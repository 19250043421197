<template>
  <div class="profile-info-container">
    <v-row>
      <v-col cols="4">
        <v-img :src="profileData.image" width="50" height="50" />
      </v-col>
      <v-col cols="8" class="profile-info-content">
        <div>ชื่อพนักงาน</div>
        <div class="profile-info-name">{{ profileData.name }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    profileData: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-info-container {
  .profile-info-content {
    line-height: 1.2;
    .profile-info-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>