import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login.vue'
import Home from '@/views/Home.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Register from '@/views/Register.vue'

import Voucher from '@/views/Auth/Voucher.vue'
import VoucherDetail from '@/views/Auth/Voucher/Detail.vue'
import VoucherEditor from '@/views/Auth/Voucher/Editor.vue'

import Shop from '@/views/Auth/Shop.vue'
import ShopEditor from '@/views/Auth/Shop/Editor.vue'

import ShopScoreManagement from '@/views/Auth/ShopScore.vue'
import ShopScoreManagementEditor from '@/views/Auth/ShopScore/Editor.vue'
import ShopScoreManagementDefaultEditor from '@/views/Auth/ShopScore/DefaultEditor.vue'
import ShopScoreManagementPromotion from '@/views/Auth/ShopScore/Promotion.vue'

import PromotionScoreManagement from '@/views/Auth/PromotionScore.vue'
import PromotionScoreManagementEditor from '@/views/Auth/PromotionScore/Editor.vue'

import Customer from '@/views/Auth/Customer.vue'
import DashboardReport from '@/views/Auth/Dashboard.vue'

import SearchOperation from '@/views/Auth/Operation.vue'
import CustomerProfile from '@/views/Auth/Customer/CustomerProfile.vue'
import CustomerRegister from '@/views/Auth/Customer/CustomerRegister.vue'
import AddReceipt from '@/views/Auth/Customer/AddReceipt.vue'
import RedeemPoint from '@/views/Auth/Customer/RedeemPoint.vue'

import Receipt from '@/features/Receipt.vue'

import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'homeLayout',
    },
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: 'authLayout',
    },
    component: Home,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      layout: 'homeLayout',
    },
    component: ResetPassword,
  },
  {
    path: '/voucher',
    name: 'Voucher',
    meta: {
      layout: 'authLayout',
    },
    component: Voucher,
  },
  {
    path: '/voucher/editor',
    name: 'Voucher-Editor',
    props: true,
    meta: {
      layout: 'authLayout',
    },
    component: VoucherEditor,
  },
  {
    path: '/voucher/:voucherId',
    name: 'Voucher-Detail',
    props: true,
    meta: {
      layout: 'authLayout',
    },
    component: VoucherDetail,
  },
  {
    path: '/shop',
    name: 'Shop',
    meta: {
      layout: 'authLayout',
    },
    component: Shop,
  },
  {
    path: '/shop/editor',
    name: 'Shop-Editor',
    props: true,
    meta: {
      layout: 'authLayout',
    },
    component: ShopEditor,
  },
  {
    path: '/shop-score-management',
    name: 'ShopScoreManagement',
    meta: {
      layout: 'authLayout',
    },
    component: ShopScoreManagement,
  },
  {
    path: '/shop-score-management/editor',
    name: 'ShopScoreManagement-Editor',
    props: true,
    meta: {
      layout: 'authLayout',
    },
    component: ShopScoreManagementEditor,
  },
  {
    path: '/shop-score-management/defaultEditor',
    name: 'ShopScoreManagement-DefaultEditor',
    props: true,
    meta: {
      layout: 'authLayout',
    },
    component: ShopScoreManagementDefaultEditor,
  },
  {
    path: '/shop-score-management/promotion',
    name: 'ShopScoreManagementPromotion',
    props: true,
    meta: {
      layout: 'authLayout',
    },
    component: ShopScoreManagementPromotion,
  },
  {
    path: '/promotion-score-management',
    name: 'PromotionScoreManagement',
    meta: {
      layout: 'authLayout',
    },
    component: PromotionScoreManagement,
  },
  {
    path: '/promotion-score-management/editor',
    name: 'PromotionScoreManagement-Editor',
    props: true,
    meta: {
      layout: 'authLayout',
    },
    component: PromotionScoreManagementEditor,
  },
  {
    path: '/customer',
    name: 'Customer',
    meta: {
      layout: 'authLayout',
    },
    component: Customer,
  },
  {
    path: '/customer/:id',
    name: 'CustomerDetail',
    meta: {
      layout: 'authLayout',
    },
    component: CustomerProfile,
  },
  {
    path: '/dashboard-report',
    name: 'DashboardReport',
    meta: {
      layout: 'authLayout',
    },
    component: DashboardReport,
  },
  {
    path: '/search-operation',
    name: 'SearchOperation',
    meta: {
      layout: 'authLayout',
    },
    component: SearchOperation,
  },
  {
    path: '/operation/customers/:id/infomation',
    name: 'CustomerProfile',
    meta: {
      layout: 'authLayout',
    },
    component: CustomerProfile,
  },
  {
    path: '/operation/customers/:id/addReceipt',
    name: 'AddReceipt',
    meta: {
      layout: 'authLayout',
    },
    component: AddReceipt,
  },
  {
    path: '/operation/customers/:id/redeem',
    name: 'RedeemPoint',
    meta: {
      layout: 'authLayout',
    },
    component: RedeemPoint,
  },
  {
    path: '/search-operation/register',
    name: 'CustomerRegister',
    props: true,
    meta: {
      layout: 'authLayout',
    },
    component: CustomerRegister,
  },
  {
    path: '/operation/receipt',
    name: 'OperationReceipt',
    props: true,
    component: Receipt,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
