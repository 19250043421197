<template>
  <div class="editor-shop-score-container">
    <h1 class="mb-4">โปรโมชั่นคะแนนรายร้านค้า</h1>
    <v-row class="text-field">
      <v-col class="pa-0" cols="12" sm="6" lg="2">
        <label>รหัสร้านค้า</label>
      </v-col>
      <v-col class="pl-0 pt-0 pb-0 pr-lg-2 pr-0" cols="12" sm="6" lg="4">
        <v-text-field
          placeholder="รหัสร้านค้า"
          outlined
          dense
          color="black"
          background-color="#d0d0d0"
          disabled
          :value="promotionForm.shopCode"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col class="pr-0 pt-0 pb-0 pl-lg-2 pl-0" cols="12" sm="6" lg="2">
        <label>จำนวนบาท</label>
      </v-col>
      <v-col class="pa-0" cols="12" sm="6" lg="4">
        <v-text-field
          placeholder="จำนวนบาท"
          outlined
          dense
          color="black"
          background-color="#d0d0d0"
          disabled
          @keypress="validateNumeric"
          :value="$numberWithCommas(promotionForm.amount_per_point)"
          :rules="[rules.required]"
        />
      </v-col>
    </v-row>
    <v-row class="text-field">
      <v-col class="pa-0" cols="12" sm="6" lg="2">
        <label>ชื่อร้านค้า</label>
      </v-col>
      <v-col class="pl-0 pt-0 pb-0 pr-lg-2 pr-0" cols="12" sm="6" lg="4">
        <v-text-field
          placeholder="ชื่อร้านค้า"
          outlined
          dense
          color="black"
          background-color="#d0d0d0"
          disabled
          v-model="promotionForm.name"
          :rules="[rules.required]"
        />
      </v-col>
      <v-col class="pr-0 pt-0 pb-0 pl-lg-2 pl-0" cols="12" sm="6" lg="2">
        <label>คะแนน</label>
      </v-col>
      <v-col class="pa-0" cols="12" sm="6" lg="4">
        <v-text-field
          placeholder="คะแนน"
          outlined
          dense
          color="black"
          background-color="#d0d0d0"
          disabled
          @keypress="validateNumeric"
          v-model="promotionForm.point"
          :rules="[rules.required]"
        />
      </v-col>
    </v-row>
    <v-divider class="mt-2" />
    <v-form ref="form" :disabled="isLoading" @submit.prevent="submitForm">
      <div v-if="create || edit">
        <h1 class="mb-4">{{ create ? "สร้าง" : "แก้ไข" }}คะแนนโปรโมชั่น</h1>
        <v-row class="text-field">
          <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
            <label>ชื่อโปรโมชั่น</label>
          </v-col>
          <v-col class="pa-0" cols="12" sm="4">
            <v-text-field
              placeholder="ชื่อโปรโมชั่น"
              outlined
              dense
              color="black"
              background-color="white"
              v-model="formData.name"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-row class="text-field">
          <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
            <label>ประเภท</label>
          </v-col>
          <v-col class="pa-0" cols="12" sm="4">
            <v-combobox
              :disabled="edit"
              v-model="formData.periodType"
              :items="periodTypeList"
              placeholder="ประเภท"
              item-value="value"
              item-text="name"
              outlined
              single-line
              dense
              color="black"
              :background-color="edit ? '#d0d0d0' : 'white'"
              :rules="[rules.required]"
              @change="changePeriod"
            />
          </v-col>
        </v-row>
        <v-row
          class="text-field mb-6"
          v-if="
            formData.periodType === '' ||
            formData.periodType === null ||
            (formData.periodType &&
              formData.periodType.value === 'specific-date')
          "
        >
          <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
            <label>วันเริ่มต้น</label>
          </v-col>
          <v-col class="pa-0" cols="12" sm="4">
            <DatePicker
              :disabled="!formData.periodType"
              :text-field-props="textFieldeProps"
              :date-picker-props="colorProps"
              :time-picker-props="colorProps"
              :tab-props="colorProps"
              placeholder="วันเริ่มต้น"
              v-model="formData.startDate"
            />
          </v-col>
        </v-row>
        <v-row
          class="text-field mb-6"
          v-if="
            formData.periodType === '' ||
            formData.periodType === null ||
            (formData.periodType &&
              formData.periodType.value === 'specific-date')
          "
        >
          <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
            <label>วันสิ้นสุด</label>
          </v-col>
          <v-col class="pa-0" cols="12" sm="4">
            <DatePicker
              :disabled="!formData.periodType"
              :text-field-props="textFieldeProps"
              :date-picker-props="colorProps"
              :time-picker-props="colorProps"
              :tab-props="colorProps"
              placeholder="วันสิ้นสุด"
              v-model="formData.endDate"
            />
          </v-col>
        </v-row>
        <v-row class="text-field">
          <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
            <label>ตัวคูณ</label>
          </v-col>
          <v-col class="pa-0" cols="12" sm="4">
            <v-text-field
              placeholder="ตัวคูณคะแนนที่ได้รับต่อใบเสร็จ"
              outlined
              dense
              color="black"
              background-color="white"
              @keypress="validateNumeric"
              v-model="formData.multiplier"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="promotionList"
          :server-items-length="promotions && promotions.total"
          :footer-props="{
            itemsPerPageOptions,
          }"
          :options.sync="options"
          no-data-text="ไม่พบข้อมูล"
          no-results-text="ไม่พบข้อมูล"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="white" class="promotion-toolbar">
              <v-row class="align-center">
                <v-col cols="12" md="3" lg="3" class="px-md-0">
                  <v-btn color="#f7941e" @click="createPromotion">
                    <v-icon small class="mr-1">mdi-plus</v-icon
                    >สร้างโปรโมชั่นร้านค้า
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.id="{ item }">
            {{ $numberWithCommas(item.id) }}
          </template>
          <template v-slot:item.startDate="{ item }">
            <div v-if="item.periodType === 'specific-date'">
              {{ generateDate(item.startDate) }}
            </div>
            <div v-else>
              {{ convertPeroidType(item.periodType) }}
            </div>
          </template>
          <template v-slot:item.endDate="{ item }">
            <div v-if="item.periodType === 'specific-date'">
              {{ generateDate(item.endDate) }}
            </div>
            <div v-else>
              {{ convertPeroidType(item.periodType) }}
            </div>
          </template>
          <template v-slot:item.multiplier="{ item }">
            {{ $numberWithCommas(item.multiplier) }}
          </template>
          <template v-slot:item.edit="{ item }">
            <v-btn
              v-if="!item.disableEdit"
              class="action-button"
              depressed
              small
              @click="editItem(item)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <div v-else>-</div>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-btn
              v-if="!item.disableDelete"
              class="action-button"
              depressed
              small
              @click="deleteItem(item)"
            >
              <v-icon small>mdi-trash-can-outline</v-icon>
            </v-btn>
            <div v-else>-</div>
          </template>
        </v-data-table>
      </div>
      <v-divider class="mt-5" />
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2" v-if="create || edit">
          <v-btn
            class="mt-4"
            color="#f7941e"
            type="submit"
            block
            :disabled="isLoading || validate"
          >
            บันทึก
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn class="mt-4" outlined block @click="backShopScore">กลับ</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <ConfirmModal v-model="conf.status" :data="conf" @confirm="deleteData" />
  </div>
</template>

<script>
import moment from "moment";
import { cloneDeep, findIndex, isEmpty } from "lodash";

import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import ConfirmModal from "@/components/ConfirmModal";
import DatePicker from "@/components/DatePicker";

export default {
  mixins: [Utils],
  components: {
    ConfirmModal,
    DatePicker,
  },
  computed: {
    promotionList() {
      const { promotions, options } = this;
      const start = (options.page - 1) * options.itemsPerPage + 1;
      const promotionList =
        promotions?.data?.map((row, index) => {
          const list = {
            index: index + start,
            ...row,
          };
          return list;
        }) || [];
      return promotionList;
    },
    validate() {
      const { name, periodType, multiplier, startDate, endDate } =
        this.formData;
      if (
        !(
          (name &&
            periodType &&
            multiplier &&
            periodType?.value !== "specific-date") ||
          (name &&
            periodType &&
            multiplier &&
            periodType?.value === "specific-date" &&
            startDate &&
            endDate)
        )
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    const { shop } = this;
    if (isEmpty(shop)) this.backShopScore();
    const cloneShop = cloneDeep(shop);
    this.promotionForm = {
      // id: cloneShop?.id || "",
      shopCode: cloneShop?.shop?.code?.toString() || "",
      shopId: cloneShop?.shopId || "",
      name: cloneShop?.shop?.name || "",
      amount_per_point: cloneShop?.amount_per_point?.toString() || "",
      point: cloneShop?.point?.toString() || 1,
    };
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  data: () => ({
    periodTypeList: [
      { name: "วันเกิด", value: "birth-date" },
      { name: "สัปดาห์เกิด", value: "week-of-birth" },
      { name: "เดือนเกิด", value: "month-of-birth" },
      { name: "เฉพาะวันที่", value: "specific-date" },
    ],
    promotionForm: {
      shopCode: "",
      shopId: "",
      name: "",
      amount_per_point: "",
      point: 1,
    },
    formData: {
      name: "",
      periodType: "",
      startDate: null,
      endDate: null,
      multiplier: "",
    },
    rules: {
      required: (value) => !!value || "Require field.",
    },
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    headers: [
      { text: "ลำดับ", value: "index", sortable: false },
      { text: "ชื่อโปรโมชั่น", value: "name" },
      {
        text: "วันเริ่มต้น",
        value: "startDate",
        sortable: false,
        align: "center",
      },
      {
        text: "วันสิ้นสุด",
        value: "endDate",
        sortable: false,
        align: "center",
      },
      {
        text: "ตัวคูณ",
        value: "multiplier",
        sortable: false,
        align: "center",
      },
      { text: "แก้ไข", value: "edit", sortable: false, align: "center" },
      { text: "ลบ", value: "delete", sortable: false, align: "center" },
    ],
    conf: {
      status: false,
    },
    create: false,
    edit: false,
    textFieldeProps: {
      outlined: true,
      dense: true,
      hideDetails: true,
      color: "black",
      backgroundColor: "white",
    },
    colorProps: {
      color: "#f7941e",
    },
  }),
  props: {
    shop: {
      type: Object,
    },
  },
  methods: {
    clearDate() {
      this.formData = {
        ...this.formData,
        startDate: null,
        endDate: null,
      };
    },
    changePeriod() {
      this.clearDate();
    },
    async getInitialData() {
      const { shop } = this;
      let condition = {
        shopId: shop?.shopId,
      };

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getShopPromotions(condition);
    },
    generateDate(n) {
      return n !== null ? moment(n).format("DD/MM/YYYY HH:mm:ss") : "-";
    },
    convertPeroidType(n) {
      const { periodTypeList } = this;
      const index = findIndex(periodTypeList, (i) => i.value === n);
      return periodTypeList[index].name;
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    backShopScore() {
      const { create, edit } = this;
      if (create || edit) {
        this.$refs.form.reset();
        this.clearDate();
        this.create = false;
        this.edit = false;
      } else {
        this.$router.push({
          name: "ShopScoreManagement",
        });
      }
    },
    async submitForm() {
      const { create, edit, formData, shop } = this;
      const cloneShop = cloneDeep(shop);
      const { shopId } = cloneShop;
      const criteriaRef = [];
      criteriaRef.push(shopId);
      let submitForm = {
        ...formData,
        criteriaType: "shop",
        criteriaRef,
        periodType: formData?.periodType?.value,
        multiplier: parseFloat(formData?.multiplier),
      };
      if (formData?.periodType?.value === "specific-date") {
        submitForm = {
          ...submitForm,
          startDate: moment(formData?.startDate).format(),
          endDate: moment(formData?.endDate).format(),
        };
      } else {
        submitForm = {
          ...submitForm,
          startDate: null,
          endDate: null,
        };
      }
      if (create) {
        const res = await this.createPromotions(submitForm);
        const { status } = res;
        if (status === 201) {
          this.backShopScore();
        }
      }
      if (edit) {
        const res = await this.editPromotions(submitForm);
        const { status } = res;
        if (status === 200) {
          this.backShopScore();
        }
      }
    },
    createPromotion() {
      this.create = true;
    },
    editItem(item) {
      const { periodTypeList } = this;
      const index = findIndex(
        periodTypeList,
        (i) => i.value === item?.periodType
      );
      this.formData = {
        id: item?.id,
        name: item?.name,
        periodType: periodTypeList[index] || "",
        startDate: item?.startDate !== null ? new Date(item?.startDate) : "",
        endDate: item?.startDate !== null ? new Date(item?.endDate) : "",
        multiplier: item?.multiplier,
      };
      this.edit = true;
    },
    deleteItem(data) {
      this.conf = {
        ...this.conf,
        text: `โปรโมชั่น: ${data?.name}`,
        status: true,
        data,
      };
    },
    async deleteData(value = false) {
      if (value) {
        await this.deletePromotions({ id: this.conf?.data?.id });
      }
      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss">
$light-grey: #f6f6f6;

.editor-shop-score-container {
  padding: 40px 65px;
  .promotion-toolbar {
    .v-toolbar__content {
      background: $light-grey !important;
      padding-left: 12px;
    }
  }
}
</style>