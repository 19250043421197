<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :class="className"
        :placeholder="label"
        readonly
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        hide-details
        :clearable="clearable"
        @click:clear="clearable ? $emit('clear') : () => {}"
        v-model="displayValue"
      />
    </template>
    <v-date-picker
      color="#f7941e"
      :min="!disableMin ? min : undefined"
      :range="range"
      @change="save"
      v-model="dateValue"
    />
  </v-menu>
</template>

<script>
import { forEach } from "lodash";
import moment from "moment";

export default {
  computed: {
    displayValue() {
      const { value } = this;
      let result = [];
      forEach(value, (i) => {
        result.push(moment(i).format("DD/MM/YYYY"));
      });
      return result.join(" ~ ");
    },
  },
  data: () => ({
    min: new Date().toISOString().substring(0, 10),
    dialog: false,
    dateValue: null,
  }),
  mounted() {
    this.dateValue = this.value;
  },
  methods: {
    save(date) {
      this.$emit("input", date);
    },
  },
  props: {
    disableMin: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
    },
    label: {
      type: String,
      default: "Date Input",
    },
    range: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, String],
    },
    updateInput: {
      type: Function,
    },
  },
};
</script>