<template>
  <div class="voucher-detail-container">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="(voucherCode && voucherCode.data) || []"
      :server-items-length="voucherCode && voucherCode.total"
      :footer-props="{
        itemsPerPageOptions,
      }"
      :options.sync="options"
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" :height="checkScreen">
          <v-row class="align-center">
            <v-col cols="12" md="6">
              <h1>รายละเอียด Voucher : {{ voucherName }}</h1>
            </v-col>
            <v-col cols="12" offset-md="2" md="4">
              <v-text-field
                v-model="keyword"
                outlined
                dense
                hide-details
                label="Search จากรหัส Voucher"
                append-icon="mdi-magnify"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.customerId="{ item }">
        <span v-if="item.customerId === null || item.customerId === undefined"
          >-</span
        >
        <span v-else>{{ item.customerId }}</span>
      </template>
      <template v-slot:item.usedAt="{ item }">
        {{ generateDate(item.usedAt) }}
      </template>
      <template v-slot:item.user="{ item }">
        <span
          v-if="
            (item.userId === null) | (item.userId === undefined) ||
            item.userId === 0
          "
          >-</span
        >
        <span v-else>{{
          `(${item.user.code}) ${item.user.firstName} ${item.user.lastName}`
        }}</span>
      </template>
      <template v-slot:item.actBy="{ item }">
        <span
          v-if="
            (item.pointTransaction === null) |
              (item.pointTransaction === undefined)
          "
          >-</span
        >
        <span v-else>{{ item.pointTransaction.actBy.name }}</span>
      </template>
      <template v-slot:item.used="{ item }">
        <v-btn
          class="card-button"
          block
          :color="item.usedAt !== null ? '#d0d0d0' : '#f7941e'"
          depressed
          :ripple="false"
        >
          {{ item.usedAt !== null ? "บัตรถูกใช้แล้ว" : "บัตรยังไม่ถูกใช้" }}
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

export default {
  mixins: [Utils],
  computed: {
    voucherName() {
      const { voucherDetail } = this;
      return voucherDetail?.name || "";
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return "64px";
      }
      return "148px";
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  data: () => ({
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    headers: [
      { text: "รหัส Voucher", value: "code" },
      { text: "ลูกค้า", value: "user" },
      { text: "วันที่แลก", value: "usedAt" },
      { text: "เจ้าหน้าที่", value: "actBy", sortable: false },
      { text: "สถานะ", value: "used" },
    ],
    keyword: "",
    windowWidth: window.innerWidth,
  }),
  props: {
    voucherId: {
      type: [Number, String],
    },
  },
  methods: {
    async getInitialData() {
      const { voucherId } = this;

      let condition = {
        voucherId,
      };

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getVoucherDetail({ voucherId });
      await this.getVoucherCode(condition);
    },
    generateDate(n) {
      return n !== null ? moment(n).format("DD/MM/YYYY HH:mm:ss") : "-";
    },
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    keyword: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.voucher-detail-container {
  padding: 40px 65px;
  .card-button {
    cursor: default;
    &::before {
      opacity: 0;
    }
  }
}
</style>