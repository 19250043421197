<template>
  <div class="voucher-management-container">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="(vouchers && vouchers.data) || []"
      :server-items-length="vouchers && vouchers.total"
      :footer-props="{
        itemsPerPageOptions,
      }"
      :options.sync="options"
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <h1>จัดการ Vouchers</h1>
          <v-spacer />
          <v-btn color="#f7941e" @click="createVoucher">
            <v-icon small class="mr-1">mdi-plus</v-icon>สร้าง Voucher
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.id="{ item }">
        {{ $numberWithCommas(item.id) }}
      </template>
      <template v-slot:item.point="{ item }">
        {{ $numberWithCommas(item.point) }}
      </template>
      <template v-slot:item.info="{ item }">
        <v-btn class="action-button" depressed small @click="viewItem(item)">
          <v-icon small>mdi-magnify</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn class="action-button" depressed small @click="editItem(item)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn class="action-button" depressed small @click="deleteItem(item)">
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <ConfirmModal v-model="conf.status" :data="conf" @confirm="deleteData" />
  </div>
</template>

<script>
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import ConfirmModal from "@/components/ConfirmModal";

export default {
  mixins: [Utils],
  components: {
    ConfirmModal,
  },
  data: () => ({
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    headers: [
      { text: "ลำดับ", value: "id", align: "center", sortable: false },
      { text: "ชื่อบัตรของรางวัล", value: "name" },
      { text: "คะแนน", value: "point", sortable: false },
      { text: "รายละเอียด", value: "info", sortable: false, align: "center" },
      { text: "แก้ไข", value: "edit", sortable: false, align: "center" },
      { text: "ลบ", value: "delete", sortable: false, align: "center" },
    ],
    conf: {
      status: false,
    },
  }),
  mounted() {
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  methods: {
    async getInitialData() {
      let condition = {};

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      // show all
      if (this.options && this.options.itemsPerPage<0) {
        condition.listType = "all"
      }

      await this.getVouchers(condition);
    },
    createVoucher() {
      this.$router.push({
        name: "Voucher-Editor",
        params: { createVoucher: true },
      });
    },
    viewItem(item) {
      this.$router.push({
        path: "/voucher/" + item?.id,
      });
    },
    editItem(item) {
      this.$router.push({
        name: "Voucher-Editor",
        params: { voucher: item },
      });
    },
    deleteItem(data) {
      this.conf = {
        ...this.conf,
        text: `Voucher: ${data?.name}`,
        status: true,
        data,
      };
    },
    async deleteData(value = false) {
      if (value) {
        await this.deleteVouchers({ id: this.conf?.data?.id });
      }
      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.voucher-management-container {
  padding: 40px 65px;
  .action-button {
    min-width: 28px;
    padding: 0;
  }
}
</style>