<template>
  <div class="container">
    <h1 class="mb-4">ลงทะเบียนข้อมูลลูกค้าใหม่</h1>
    <v-form
      :disabled="isLoading"
      v-model="valid"
      @submit.prevent="createCustomer"
    >
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="4" lg="2">
          <div>คำนำหน้า / Title<span class="red--text">*</span></div>
          <v-combobox
            v-model="formData.title"
            :items="titleItems"
            placeholder="กรุณาเลือก"
            item-value="value"
            item-text="name"
            outlined
            dense
            color="black"
            background-color="white"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4" lg="5">
          <div>ชื่อ / Name<span class="red--text">*</span></div>
          <v-text-field
            placeholder="ชื่อ / Name"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.firstname"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4" lg="5">
          <div>นามสกุล / Last Name<span class="red--text">*</span></div>
          <v-text-field
            placeholder="นามสกุล  / Last Name"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.lastname"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="6">
          <div>อีเมล / E-mail Address</div>
          <v-text-field
            placeholder="อีเมล / E-mail Address"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.email"
            :rules="[rules.emailValidate]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <div>
            เบอร์โทรศัพท์ / Mobile Phone No.<span class="red--text">*</span>
          </div>
          <v-text-field
            placeholder="เบอร์โทรศัพท์ / Mobile Phone No."
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.phone"
            @keypress="validateNumeric"
            :rules="[rules.required, rules.max]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="6">
          <div>เพศ / Gender<span class="red--text">*</span></div>
          <v-radio-group
            v-model="formData.gender"
            class="mt-0"
            row
            :rules="[rules.required]"
          >
            <v-radio label="ชาย / Men" value="men" />
            <v-radio label="หญิง / Women" value="women" />
            <v-radio label="อื่นๆ" value="other" />
          </v-radio-group>
        </v-col>
      </v-row>

      <v-divider class="mt-2" />
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn
            class="mt-4"
            color="#f7941e"
            type="submit"
            block
            :disabled="isLoading || validate || !valid"
          >
            บันทึก
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn class="mt-4" block @click="onCancel"> กลับไปหน้าค้นหา </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <ConfirmModal
      disabledCancel
      v-model="conf.status"
      :data="conf"
      @confirm="conf = { ...conf, status: false }"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { errorMessage } from '@/plugins/common'
import { REQUEST_POST } from '@/store/API_Request'

import ConfirmModal from '@/components/ConfirmModal'

export default {
  components: {
    ConfirmModal,
  },
  props: {
    phone: {
      type: String,
    },
  },
  data: () => ({
    valid: false,
    isLoading: false,
    formData: {
      title: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      gender: '',
    },
    titleItems: [
      { value: 'mr', name: 'นาย' },
      { value: 'ms', name: 'นาง' },
      { value: 'mrs', name: 'นางสาว' },
    ],
    rules: {
      required: (value) => !!value || 'Require field.',
      emailValidate: (email) => {
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
        if (isEmpty(email) || re.test(String(email).toLowerCase())) {
          return true
        }
        return 'Invalid format.'
      },
      max: (value) => (!!value && value.length === 10) || 'Invalid format.',
    },
    conf: {
      status: false,
    },
  }),
  mounted() {
    const { phone } = this
    if (phone) this.formData.phone = phone
  },
  computed: {
    validate() {
      const { title, firstname, lastname, phone, gender } = this.formData
      if (!(title && firstname && lastname && phone && gender)) return true
      return false
    },
  },
  methods: {
    validateNumeric(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode >= 48 && charCode <= 57) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    async createCustomer() {
      this.isLoading = true
      try {
        const { formData } = this
        const payload = {
          title: formData.title?.value,
          firstName: formData.firstname,
          lastName: formData.lastname,
          email: formData.email,
          phone: formData.phone,
          gender: formData.gender,
        }

        const res = await REQUEST_POST('/api/users/customer', payload)

        if (res.status == 201) {
          this.$router.push({
            name: 'CustomerProfile',
            params: { id: res.data.id },
          })
        }
        this.isLoading = false
      } catch (e) {
        const error = errorMessage(e)
        let text = ''
        switch (error) {
          case 'This email already registered: register error':
            text = 'อีเมล์นี้เป็นสมาชิก The Street Point ในระบบแล้ว'
            break
          case 'This phone number already registered: register error':
            text = 'หมายเลขนี้เป็นสมาชิก The Street Point ในระบบแล้ว'
            break
          case 'Invalid data':
            text = 'กรุณากรอกข้อมูลให้ถูกต้อง'
            break
          default:
            break
        }
        this.conf = {
          ...this.conf,
          status: true,
          title: 'แจ้งเตือน!',
          text,
        }
        this.isLoading = false
      }
    },
    updateDateInput(e) {
      this.formData.birthDate = e
    },
    onClear() {
      this.formData.birthDate = undefined
    },
    onCancel() {
      this.$router.push({
        name: 'SearchOperation',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  padding: 40px 65px;
}
</style>
