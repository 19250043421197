<template>
  <div class="editor-shop-container">
    <h1 class="mb-4">{{ createShop ? "สร้าง" : "แก้ไขข้อมูล" }}ร้านค้า</h1>
    <v-form :disabled="isLoading" v-model="valid" @submit.prevent="submitForm">
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>รหัสร้านค้า</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="รหัสร้านค้า"
            outlined
            dense
            color="black"
            background-color="white"
            @keypress="validateEnglish"
            v-model="formData.code"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>ชื่อร้านค้า</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="ชื่อร้านค้า"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.name"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>เบอร์โทรศัพท์</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="เบอร์โทรศัพท์"
            outlined
            dense
            color="black"
            background-color="white"
            @keypress="validateNumeric"
            v-model="formData.phoneNumber"
            :rules="[rules.required, rules.max]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>ชั้น</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="ชั้น"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.floor"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>พื้นที่</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="พื้นที่"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.areaSize"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <!-- <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>หมวด</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-combobox
            v-model="formData.categoryIds"
            :items="shopCategories.data"
            placeholder="หมวด"
            item-value="id"
            item-text="name"
            outlined
            single-line
            dense
            multiple
            color="black"
            background-color="white"
          />
        </v-col>
      </v-row> -->
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>ประเภท</label>
          <!-- <label>หมวด</label> -->
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-combobox
            v-model="formData.shopTypeId"
            :items="shopTypes.data"
            placeholder="ประเภท"
            item-value="id"
            item-text="name"
            outlined
            single-line
            dense
            color="black"
            background-color="white"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-2" />
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn
            class="mt-4"
            color="#f7941e"
            type="submit"
            block
            :disabled="isLoading || validate || !valid"
          >
            บันทึก
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn class="mt-4" outlined block @click="backShop">กลับ</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Utils from "@/mixin";
import { cloneDeep, forEach, isEmpty, reduce, size } from "lodash";

export default {
  mixins: [Utils],
  async created() {
    const condition = { listType: "all" };
    // this.getShopCategories(condition);
    this.getShopTypes(condition);
  },
  computed: {
    validate() {
      const { code, name, phoneNumber, floor, areaSize, shopTypeId } =
        this.formData;
      if (!(code && name && phoneNumber && floor && areaSize && shopTypeId))
        return true;
      return false;
    },
  },
  mounted() {
    const { createShop, shop } = this;
    if (!createShop) {
      if (isEmpty(shop)) this.backShop();
      const cloneShop = cloneDeep(shop);
      this.formData = {
        id: cloneShop?.id || "",
        code: cloneShop?.code || "",
        name: cloneShop?.name || "",
        phoneNumber: cloneShop?.phoneNumber?.toString() || "",
        floor: cloneShop?.floor || "",
        areaSize: cloneShop?.areaSize || "",
      };
    }
  },
  data: () => ({
    valid: false,
    formData: {
      code: "",
      name: "",
      phoneNumber: "",
      floor: "",
      areaSize: "",
      shopTypeId: "",
      // categoryIds: "",
    },
    rules: {
      required: (value) => !!value || "Require field.",
      max: (value) =>
        (!!value && value.length === 10) ||
        value.length === 9 ||
        "Invalid format.",
    },
  }),
  props: {
    createShop: {
      type: Boolean,
      default: false,
    },
    shop: {
      type: Object,
    },
  },
  methods: {
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    validateEnglish(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode >= 48 && charCode <= 57) ||
        (charCode >= 97 && charCode <= 122)
      ) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    backShop() {
      this.$router.push({
        name: "Shop",
      });
    },
    async submitForm() {
      const { createShop, formData } = this;
      // const categoryIds = reduce(
      //   formData?.categoryIds,
      //   (a, b) => {
      //     a.push(b.id);
      //     return a;
      //   },
      //   []
      // );
      const shopTypeId = formData?.shopTypeId?.id || "";
      if (createShop) {
        const submitForm = {
          ...formData,
          // categoryIds,
          shopTypeId,
          areaSize: parseInt(formData.areaSize),
        };
        await this.createShops(submitForm);
      } else {
        const submitForm = {
          ...formData,
          // categoryIds,
          shopTypeId,
          areaSize: parseInt(formData.areaSize),
        };
        await this.editShops(submitForm);
      }
      this.$router.push({ name: "Shop" });
    },
    reassignShopCategory() {
      const { createShop, shop, shopCategories } = this;
      if (!createShop) {
        const cloneShop = cloneDeep(shop);
        let categoryIds = [];
        if (size(cloneShop?.categories) > 0) {
          const reduceCategory = reduce(
            shopCategories?.data,
            (a, b) => {
              forEach(cloneShop?.categories, (i) => {
                if (i.id === b.id) {
                  a.push(b);
                }
              });
              return a;
            },
            []
          );
          categoryIds = reduceCategory;
        } else if (size(cloneShop?.categories) === 1) {
          forEach(shopCategories?.data, (i) => {
            if (i.id === cloneShop?.categories) {
              categoryIds = i;
            }
          });
        }
        this.formData = {
          ...this.formData,
          categoryIds,
        };
      }
    },
    reassignShopType() {
      const { createShop, shop, shopTypes } = this;
      if (!createShop) {
        const cloneShop = cloneDeep(shop);
        let shopTypeId = "";
        forEach(shopTypes?.data, (i) => {
          if (i.id === cloneShop?.type?.id) {
            shopTypeId = i;
          }
        });
        this.formData = {
          ...this.formData,
          shopTypeId,
        };
      }
    },
  },
  watch: {
    // shopCategories: {
    //   handler() {
    //     this.reassignShopCategory();
    //   },
    //   deep: true,
    // },
    shopTypes: {
      handler() {
        this.reassignShopType();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-shop-container {
  padding: 40px 65px;
}
</style>