<template>
  <div class="container">
    <h1 class="d-flex mb-4">
      ชื่อลูกค้า {{ customerName }}
      <div class="ml-auto">
        สถานะ PDPA:
        <span class="green-color" v-if="pdpaStatus === true">Yes</span
        ><span class="red-color" v-else>No</span>
      </div>
    </h1>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="voucherList"
      :server-items-length="voucherList && voucherList.length"
      :footer-props="{
        itemsPerPageOptions,
      }"
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="align-center">
            <v-col cols="6" sm="3" lg="3">
              <form @submit.prevent="addReceipt">
                <v-text-field
                  ref="code"
                  v-model="keyword"
                  outlined
                  dense
                  hide-details
                  label="กรอก Code"
                />
              </form>
            </v-col>
            <v-col cols="6" sm="3" lg="2">
              <v-btn color="#f7941e" @click="addReceipt" :disabled="!keyword">
                <v-icon small class="mr-1">mdi-plus</v-icon>เพิ่มของรางวัล
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" lg="7" class="text-right">
              <v-btn
                color="#f7941e"
                class="mr-1"
                @click="clearReceipt"
                :disabled="voucherList && voucherList.length === 0"
                >ล้างข้อมูล</v-btn
              >
              <v-btn
                color="#f7941e"
                class="mr-1"
                @click="printReceipt"
                :disabled="voucherList && voucherList.length === 0"
                >พิมพ์เอกสาร</v-btn
              >
              <v-btn color="#f7941e" @click="saveReceipt" :disabled="!flag"
                >บันทึกของรางวัล</v-btn
              >
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.voucher.point="{ item }">
        {{ $numberWithCommas(item.voucher.point) }}
      </template>
    </v-data-table>
    <div class="remark">
      *กรุณาทำการ "พิมพ์เอกสาร" ก่อนระบบจึงจะสามารถกด "บันทึก" ได้
    </div>
    <ConfirmModal
      disabledCancel
      v-model="conf.status"
      :data="conf"
      @confirm="conf = { ...conf, status: false }"
    />
  </div>
</template>

<script>
import { forEach, size } from "lodash";
import Utils from "@/mixin";

import { itemsPerPageOptions } from "@/plugins/common";

import ConfirmModal from "@/components/ConfirmModal";

export default {
  mixins: [Utils],
  components: {
    ConfirmModal,
  },
  computed: {
    voucherList() {
      return this.dataList;
    },
    customerName() {
      const { customerProfile } = this;
      if (customerProfile == null) return "-";
      const title_map = {
        mr: "นาย",
        ms: "นาง",
        mrs: "นางสาว",
      };
      const title = title_map[customerProfile.title];
      return `${title} ${customerProfile.firstName} ${customerProfile.lastName}`;
    },
  },
  data: () => ({
    dataList: [],
    itemsPerPageOptions: [],
    headers: [
      { text: "บัตรของขวัญ", value: "voucher.name" },
      { text: "Voucher Code", value: "code" },
      { text: "คะแนนที่ใช้", value: "voucher.point", sortable: false },
    ],
    conf: {
      status: false,
    },
    keyword: "",
    flag: false,
    pdpaStatus: false,
  }),
  methods: {
    async getInitialData() {
      await this.getCustomerProfile(this.$route.params.id);

      const checkConsent = await this.checkUserConsent({
        userId: this.$route.params.id,
      });
      if (size(checkConsent?.data) === 0) this.pdpaStatus = true;
    },
    async addReceipt() {
      const { keyword } = this;
      const res = await this.searchVoucher({ code: keyword });
      if (res === undefined) {
        this.conf = {
          ...this.conf,
          status: true,
          title: "แจ้งเตือน!",
          text: "ไม่พบ Voucher ในระบบ",
        };
      } else {
        const { status } = res;
        let flag = false;
        if (status === 200) {
          const { id, userId } = res?.data;
          if (userId !== null) {
            this.conf = {
              ...this.conf,
              status: true,
              title: "แจ้งเตือน!",
              text: "Voucher นี้ถูกใช้ไปแล้ว",
            };
            flag = true;
          }
          forEach(this.dataList, (i) => {
            if (i.id === id) {
              this.conf = {
                ...this.conf,
                status: true,
                title: "แจ้งเตือน!",
                text: "Voucher นี้ถูกใช้ไปแล้ว",
              };
              flag = true;
            }
          });
          if (!flag) {
            this.dataList.push(res?.data);
            this.keyword = "";
            this.focusCode();
          }
        }
      }
    },
    async saveReceipt() {
      const { customerProfile, dataList } = this;
      let voucherCodeIds = [];
      forEach(dataList, (i) => {
        voucherCodeIds.push(i.id);
      });
      const res = await this.redeemVouchers({
        customerId: customerProfile?.id,
        voucherCodeIds,
      });
      const { status } = res;
      if (status === 200) {
        this.$router.push({
          name: "CustomerProfile",
          params: { id: this.$route.params.id },
        });
      }
    },
    clearReceipt() {
      this.dataList = [];
    },
    printReceipt() {
      const { customerProfile, voucherList } = this;
      let routeData = this.$router.resolve({
        name: "OperationReceipt",
      });
      let total = 0;
      forEach(voucherList, (i) => {
        total += i.voucher.point;
      });
      if (total <= customerProfile?.totalPoint) {
        sessionStorage.setItem(
          "customerProfile",
          JSON.stringify(customerProfile)
        );
        sessionStorage.setItem("voucherList", JSON.stringify(voucherList));
        window.open(routeData.href, "_blank");
        this.handleFlag();
      } else {
        this.conf = {
          ...this.conf,
          status: true,
          title: "แจ้งเตือน!",
          text: "คะแนนของลูกค้าไม่เพียงพอ",
        };
      }
    },
    handleFlag() {
      this.flag = true;
    },
    focusCode() {
      this.$refs.code.focus();
    },
  },
  mounted() {
    sessionStorage.removeItem("customerProfile");
    sessionStorage.removeItem("voucherList");
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
    this.focusCode();
  },
};
</script>

<style lang="scss" scoped>
$green: #4caf50;
$red: #ff5252;

.green-color {
  color: $green;
}
.red-color {
  color: $red;
}
.remark {
  text-align: right;
  padding: 8px;
  font-size: 13pt;
  color: rgba(0, 0, 0, 0.6);
}
</style>