var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"voucher-detail-container"},[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":(_vm.voucherCode && _vm.voucherCode.data) || [],"server-items-length":_vm.voucherCode && _vm.voucherCode.total,"footer-props":{
      itemsPerPageOptions: _vm.itemsPerPageOptions,
    },"options":_vm.options,"no-data-text":"ไม่พบข้อมูล","no-results-text":"ไม่พบข้อมูล"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white","height":_vm.checkScreen}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v("รายละเอียด Voucher : "+_vm._s(_vm.voucherName))])]),_c('v-col',{attrs:{"cols":"12","offset-md":"2","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"Search จากรหัส Voucher","append-icon":"mdi-magnify"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)],1)],1)]},proxy:true},{key:"item.customerId",fn:function({ item }){return [(item.customerId === null || item.customerId === undefined)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.customerId))])]}},{key:"item.usedAt",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.generateDate(item.usedAt))+" ")]}},{key:"item.user",fn:function({ item }){return [(
          (item.userId === null) | (item.userId === undefined) ||
          item.userId === 0
        )?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(`(${item.user.code}) ${item.user.firstName} ${item.user.lastName}`))])]}},{key:"item.actBy",fn:function({ item }){return [(
          (item.pointTransaction === null) |
            (item.pointTransaction === undefined)
        )?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.pointTransaction.actBy.name))])]}},{key:"item.used",fn:function({ item }){return [_c('v-btn',{staticClass:"card-button",attrs:{"block":"","color":item.usedAt !== null ? '#d0d0d0' : '#f7941e',"depressed":"","ripple":false}},[_vm._v(" "+_vm._s(item.usedAt !== null ? "บัตรถูกใช้แล้ว" : "บัตรยังไม่ถูกใช้")+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }