<template>
  <div class="shop-score-management-container">
    <v-data-table
      class="mb-4"
      :loading="isLoading"
      :headers="defaultHeaders"
      :items="(defaultPoint && defaultPoint.data) || []"
      :server-items-length="defaultPoint && defaultPoint.total"
      :footer-props="{
        itemsPerPageOptions,
      }"
      :options.sync="options"
      hide-default-footer
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" :height="checkScreen">
          <v-row class="align-center">
            <v-col cols="12" md="5" lg="6">
              <h1>จัดการคะแนนเริ่มต้น</h1>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.name> ค่าคะแนนเริ่มต้น </template>
      <template v-slot:item.amountPerPoint="{ item }">
        {{ $numberWithCommas(item.amountPerPoint) }}
      </template>
      <template v-slot:item.point> 1 </template>
      <template v-slot:item.edit="{ item }">
        <v-btn
          class="action-button"
          depressed
          small
          @click="editDefaultPoint(item)"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="pointList"
      :server-items-length="points && points.total"
      :footer-props="{
        itemsPerPageOptions,
      }"
      :options.sync="options"
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" :height="checkScreen">
          <v-row class="align-center">
            <v-col cols="12" md="5" lg="6">
              <h1>จัดการคะแนน รายร้านค้า</h1>
            </v-col>
            <v-col cols="12" md="3" lg="3" class="text-right pr-md-0">
              <v-btn color="#f7941e" @click="createShop">
                <v-icon small class="mr-1">mdi-plus</v-icon>สร้างคะแนนรายร้านค้า
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-text-field
                v-model="keyword"
                outlined
                dense
                hide-details
                label="Search"
                append-icon="mdi-magnify"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.shopId="{ item }">
        {{ $numberWithCommas(item.shopId) }}
      </template>
      <template v-slot:item.amount_per_point="{ item }">
        {{ $numberWithCommas(item.amount_per_point) }}
      </template>
      <template v-slot:item.point>
        {{ $numberWithCommas(1) }}
      </template>
      <template v-slot:item.promotion="{ item }">
        <v-btn
          v-if="!item.disablePromotion"
          class="action-button"
          depressed
          small
          @click="viewItem(item)"
        >
          <v-icon small>mdi-magnify</v-icon>
        </v-btn>
        <div v-else>-</div>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn class="action-button" depressed small @click="editItem(item)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn
          v-if="!item.disableDelete"
          class="action-button"
          depressed
          small
          @click="deleteItem(item)"
        >
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
        <div v-else>-</div>
      </template>
    </v-data-table>
    <ConfirmModal v-model="conf.status" :data="conf" @confirm="deleteData" />
  </div>
</template>

<script>
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import ConfirmModal from "@/components/ConfirmModal";

export default {
  mixins: [Utils],
  components: {
    ConfirmModal,
  },
  computed: {
    pointList() {
      const { points, options } = this;
      const start = (options.page - 1) * options.itemsPerPage + 1;
      const pointList =
        points?.data?.map((row, index) => {
          const list = {
            index: index + start,
            ...row,
          };
          return list;
        }) || [];
      return pointList;
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return "64px";
      }
      return "218px";
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  data: () => ({
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    defaultHeaders: [
      { text: "", value: "name" },
      {
        text: "บาท",
        value: "amountPerPoint",
        sortable: false,
        align: "center",
        width: "100px",
      },
      {
        text: "คะแนน",
        value: "point",
        sortable: false,
        align: "center",
        width: "100px",
      },
      {
        text: "แก้ไข",
        value: "edit",
        sortable: false,
        align: "center",
        width: "100px",
      },
    ],
    headers: [
      { text: "ลำดับ", value: "index", align: "center", sortable: false },
      { text: "ชื่อร้านค้า", value: "shop.name" },
      {
        text: "จำนวนบาท",
        value: "amount_per_point",
        sortable: false,
        align: "center",
      },
      { text: "คะแนน", value: "point", sortable: false, align: "center" },
      {
        text: "โปรโมชั่น",
        value: "promotion",
        sortable: false,
        align: "center",
      },
      { text: "แก้ไข", value: "edit", sortable: false, align: "center" },
      { text: "ลบ", value: "delete", sortable: false, align: "center" },
    ],
    conf: {
      status: false,
    },
    keyword: "",
    windowWidth: window.innerWidth,
  }),
  methods: {
    async getInitialData() {
      let condition = {};

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      // show all
      if (this.options && this.options.itemsPerPage<0) {
        condition.listType = "all"
      }

      await this.getPoints(condition);
      await this.getDefaultPoints({});
    },
    createShop() {
      this.$router.push({
        name: "ShopScoreManagement-Editor",
        params: { createShop: true },
      });
    },
    viewItem(item) {
      this.$router.push({
        name: "ShopScoreManagementPromotion",
        params: { shop: item },
      });
    },
    editDefaultPoint(item) {
      this.$router.push({
        name: "ShopScoreManagement-DefaultEditor",
        params: { point: item },
      });
    },
    editItem(item) {
      this.$router.push({
        name: "ShopScoreManagement-Editor",
        params: { shop: item },
      });
    },
    deleteItem(data) {
      this.conf = {
        ...this.conf,
        text: `ร้านค้า: ${data?.shop?.name}`,
        status: true,
        data,
      };
    },
    async deleteData(value = false) {
      const { conf } = this;
      if (value) {
        await this.deletePoints({ shopId: conf?.data?.shopId });
      }
      this.conf = {
        ...conf,
        status: false,
        data: undefined,
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    keyword: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.shop-score-management-container {
  padding: 40px 65px;
  .action-button {
    min-width: 28px;
    padding: 0;
  }
}
</style>