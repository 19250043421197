<template>
  <v-dialog v-model="value" persistent max-width="290">
    <v-card class="pa-2">
      <v-card-title class="headline">
        {{ data.title || "ยืนยันการลบข้อมูล?" }}
      </v-card-title>
      <v-card-text>
        <div>
          {{ data.text }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn v-if="!disabledCancel" outlined @click="$emit('confirm', false)">
          {{ data.cancelText || "ยกเลิก" }}
        </v-btn>
        <v-btn color="#f7941e" @click="$emit('confirm', true)">
          {{ data.confirmText || "ยืนยัน" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
    disabledCancel: {
      type: Boolean,
      default: false,
    },
  },
};
</script>