<template>
  <div class="dashboard-report-container">
    <h1>Dashboard Report</h1>
    <v-row class="mb-2">
      <v-col cols="12" sm="3">
        <DateInputRange
          label="DD/MM/YYYY"
          range
          disableMin
          :value="date"
          @input="updateDateInput"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn color="#f7941e" @click="exportData">Export</v-btn>
      </v-col>
    </v-row>
    <!-- <div class="blank-section">Dashboard Report by Power BI</div> -->
    <div v-if="ciReport !== null">
      <CIReport
        :accessToken="ciReport.accessToken"
        :embedUrl="ciReport.embedUrl"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { size } from "lodash";
import fileDownload from "js-file-download";
import Utils from "@/mixin";

import DateInputRange from "@/components/DateInputRange";

import CIReport from "@/features/CIReport.vue";

export default {
  mixins: [Utils],
  components: {
    CIReport,
    DateInputRange,
  },
  data: () => ({
    date: [
      new Date().toISOString().substring(0, 10),
      new Date().toISOString().substring(0, 10),
    ],
    ciReport: null,
  }),
  methods: {
    async exportData() {
      const { date } = this;
      let filename;
      let res;
      let startDate, endDate;
      if (size(date) > 0) {
        if (size(date) > 1) {
          startDate = moment(date[0]).format();
          endDate = moment(date[1]).format();
        } else {
          startDate = moment(date[0]).format();
          endDate = moment(date[0]).format();
        }
        const formData = {
          startDate,
          endDate,
        };
        res = await this.exportDashboard(formData);
        filename = `Dashboard_${moment().format("YYMMDD_HHmmss")}.xlsx`;
        fileDownload(res, filename);
      }
    },
    updateDateInput(e) {
      const startDate = e[0];
      const endDate = e[1];
      const cal = moment(startDate).diff(moment(endDate), "days");
      if (cal > 0) {
        this.date = [endDate, startDate];
      } else {
        this.date = e;
      }
    },
  },
  async mounted() {
    const res = await this.getDashboard();
    this.ciReport = {
      accessToken: res.token,
      embedUrl: res.embedUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard-report-container {
  padding: 40px 65px;
  .blank-section {
    background: white;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>