import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import DefaultLayout from '@/layouts/default.vue'
import HomeLayout from '@/layouts/homeLayout.vue'
import AuthLayout from '@/layouts/authLayout.vue'

import '@/assets/global.scss'

Vue.prototype.$numberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number)
}

// Vue.prototype.$numberWithCommasDescription = (text) => {
//   return text.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
// }

Vue.component('defaultLayout', DefaultLayout)
Vue.component('homeLayout', HomeLayout)
Vue.component('authLayout', AuthLayout)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
