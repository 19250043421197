<template>
  <div id="auth-layout">
    <div v-if="hamburger">
      <v-navigation-drawer temporary absolute v-model="drawer">
        <v-list-item class="nav-content"></v-list-item>
        <div class="profile-padding">
          <ProfileInfo :profileData="profileData" />
        </div>
        <v-list dense nav class="nav-padding">
          <div v-for="(i, index) in generateMenu" :key="index">
            <v-list-item
              :id="i.id"
              v-show="!i.subGroup"
              :key="`main-${index}`"
              link
              :to="i.pathName"
            >
              <v-list-item-action v-if="i.iconName">
                <v-icon>{{ i.iconName }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ i.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-show="i.subGroup"
              :key="`submain-${index}`"
              :prepend-icon="i.iconName"
              v-model="i.active"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="i.name"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :id="subItem.id"
                v-for="subItem in i.subItems"
                :key="subItem.name"
                link
                :to="subItem.pathName"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
        <template v-slot:append>
          <div class="signout-padding">
            <v-btn block dark @click="signOut()">ออกจากระบบ</v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <div v-else>
      <v-navigation-drawer permanent app floating>
        <v-list-item class="nav-content"> </v-list-item>
        <div class="profile-padding">
          <ProfileInfo :profileData="profileData" />
        </div>
        <v-list dense nav class="nav-padding">
          <div v-for="(i, index) in generateMenu" :key="index">
            <v-list-item
              :id="i.id"
              v-show="!i.subGroup"
              :key="`main-${index}`"
              link
              :to="i.pathName"
            >
              <v-list-item-action v-if="i.iconName">
                <v-icon>{{ i.iconName }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ i.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-show="i.subGroup"
              :key="`submain-${index}`"
              :prepend-icon="i.iconName"
              v-model="i.active"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="i.name"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                :id="subItem.id"
                v-for="subItem in i.subItems"
                :key="subItem.name"
                link
                :to="subItem.pathName"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
        <template v-slot:append>
          <div class="signout-padding">
            <v-btn block dark @click="signOut()">ออกจากระบบ</v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>
    <v-app-bar app color="white" flat height="90">
      <v-toolbar-title class="d-flex align-center">
        <v-btn
          icon
          v-if="hamburger"
          @click.stop="drawer = !drawer"
          class="mr-2"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-toolbar-title>
      <v-spacer />
      <v-img
        class="home-logo"
        :src="require('@/assets/logo.svg')"
        height="36px"
        contain
      />
      <v-spacer />
      <v-spacer />
    </v-app-bar>
    <slot />
    <Alert
      :snackbar="snackbar"
      :type="alertType"
      :text="alertText"
      @close="onCloseAlert"
    />
    <div v-show="isLoading" class="loading-process">
      <v-progress-circular indeterminate color="white" />
    </div>
    <CustomModal
      :modal="modal"
      @confirm="submitAction"
      disableCancel
      :disabled="termConDetail && !termConDetail.checkbox"
      :data="confirmData"
    >
      <template v-slot:header>
        <div v-if="termConDetail !== null">
          <h4>{{ termConDetail.title }}</h4>
        </div>
      </template>
      <template v-slot:body>
        <div class="container" v-if="termConDetail !== null">
          <!-- <div v-for="(item, index) in termCon" :key="index"> -->
          <div v-html="termConDetail.content" />
          <!-- </div> -->
        </div>
        <v-row class="ma-0" v-if="termConDetail !== null">
          <v-checkbox v-model="termConDetail.checkbox" />
          <div class="align-self-center">
            {{ termConDetail.agreeText }}
          </div>
        </v-row>
      </template>
    </CustomModal>
  </div>
</template>

<script>
import { forEach, isEmpty, size } from 'lodash'

import { getToken, getJWTToken, PERMISSION } from '@/plugins/auth'

import Alert from '@/components/Alert.vue'
import CustomModal from '@/components/Modal'
import ProfileInfo from '@/components/ProfileInfo.vue'

import Utils from '@/mixin'

export default {
  mixins: [Utils],
  components: {
    Alert,
    CustomModal,
    ProfileInfo,
  },
  created() {
    this.jwtToken = getJWTToken()
    window.addEventListener('resize', this.checkHamburger)
    this.flag = true
  },
  destroyed() {
    window.removeEventListener('resize', this.checkHamburger)
  },
  computed: {
    profileData() {
      const {
        // customerProfile,
        jwtToken,
      } = this
      // if (customerProfile) {
      //   return {
      //     image: require("@/assets/profile-icon.png"),
      //     name:
      //       customerProfile !== undefined
      //         ? customerProfile?.firstName + " " + customerProfile?.lastName ||
      //           customerProfile?.email
      //         : "",
      //   };
      // } else {
      return {
        image: require('@/assets/profile-icon.png'),
        name: jwtToken?.name || jwtToken?.email || '',
      }
      // }
    },
    generateMenu() {
      const { jwtToken } = this
      let menu = []
      if (jwtToken !== null) {
        const { roles } = jwtToken
        let adminCheck = false
        if (size(roles) > 0) {
          forEach(roles, (i) => {
            if (
              i === PERMISSION.SYSTEM_ADMIN ||
              (i === PERMISSION.APP_ADMIN && !adminCheck)
            ) {
              menu = menu.concat(this.adminMenu)
              adminCheck = true
            } else if (i === PERMISSION.OPERATION) {
              if (this.$route.path.indexOf('/operation/customers') < 0) {
                menu = menu.concat(this.operationMenu)
              } else {
                menu = menu.concat(this.customerMenu)
              }
            }
          })
        }
      }
      return menu
    },
    snackbar() {
      return !isEmpty(this.alert?.message) ? true : false
    },
    alertText() {
      return !isEmpty(this.alert) ? this.alert?.message : ''
    },
    alertType() {
      return !isEmpty(this.alert) ? this.alert?.type : ''
    },
    customerMenu() {
      const { id } = this.$route.params
      return [
        {
          id: 'customer-info',
          pathName: `/operation/customers/${id}/infomation`,
          name: 'ข้อมูลลูกค้า',
        },
        {
          id: 'customer-addreceipt',
          pathName: `/operation/customers/${id}/addReceipt`,
          name: 'เพิ่มบิล',
        },
        {
          id: 'customer-info',
          pathName: `/operation/customers/${id}/redeem`,
          name: 'แลกรางวัล',
        },
        {
          id: 'search-operation',
          pathName: `/search-operation`,
          name: 'กลับไปหน้าค้นหาข้อมูลลูกค้า',
        },
      ]
    },
  },
  data: () => ({
    adminMenu: [
      {
        id: 'voucher-management',
        pathName: '/voucher',
        name: 'จัดการ Vouchers',
      },
      {
        id: 'shop-management',
        pathName: '/shop',
        name: 'ข้อมูลร้านค้า',
      },
      {
        id: 'customer-management',
        pathName: '/customer',
        name: 'จัดการข้อมูลลูกค้า',
      },
      {
        id: 'shop-score-management',
        pathName: '/shop-score-management',
        name: 'จัดการคะแนน รายร้านค้า',
      },
      {
        id: 'promotion-score-management',
        pathName: '/promotion-score-management',
        name: 'จัดการคะแนน โปรโมชั่นพิเศษ',
      },
      {
        id: 'dashboard-report',
        pathName: '/dashboard-report',
        name: 'รายงาน',
      },
    ],
    operationMenu: [
      {
        id: 'search-operation',
        pathName: '/search-operation',
        name: 'ค้นหาข้อมูลลูกค้า',
      },
    ],
    hamburger: false,
    drawer: false,
    flag: false,
    jwtToken: null,
    modal: false,
    termCon: null,
    termConDetail: null,
    confirmData: {
      confirmText: 'ปิด',
    },
  }),
  async mounted() {
    const token = this.auth.token || getToken()
    if (isEmpty(token)) {
      this.$router.push({ name: 'Login' })
    }
    // this.getConsent();
    this.checkHamburger()
  },
  methods: {
    async getConsent() {
      const { jwtToken } = this
      const { id } = jwtToken
      const res = await this.checkUserConsent({ userId: id })
      const termCon = res?.data
      const result = []
      forEach(termCon, (i) => {
        const { isForce, title, content } = i
        if (isForce) {
          this.modal = true
          result.push({
            ...i,
            id: i.id,
            title,
            content,
          })
        }
      })
      if (size(result) > 0) {
        this.termCon = result
        this.termConDetail = result[0]
      }
    },
    async submitAction(event = false) {
      if (event) {
        const { termConDetail } = this
        const result = [
          {
            consentId: termConDetail.id,
            isAccept: true,
          },
        ]
        this.modal = false
        await this.acceptUserConsent(result).then(() => {
          this.getConsent()
        })
      }
    },
    checkHamburger(e) {
      const md = 992
      const innerWidth = e?.target?.innerWidth || window.innerWidth
      this.hamburger = innerWidth > md ? false : true
      // console.log(innerWidth, this.hamburger);
      if (this.flag || innerWidth > md) {
        this.drawer = false
        this.flag = false
      }
    },
    onCloseAlert() {
      this.alert.message = ''
    },
    signOut() {
      this.logout()
    },
  },
}
</script>

<style lang="scss">
$orange: #f7941e;

.branch-button {
  border-radius: 4px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  letter-spacing: 0 !important;
  font-size: 0.8125rem !important;
  padding: 2px 8px !important;
  span {
    justify-content: flex-start;
  }
}

#auth-layout {
  min-height: 100vh;
  position: relative;
  .v-navigation-drawer {
    position: fixed;
  }
  .nav-content {
    height: 90px !important;
  }
  header,
  .nav-content {
    border-width: 0 0 thin;
    border-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12) !important;
  }
  .nav-padding {
    padding: 8px 30px;
  }
  .profile-padding {
    padding: 30px;
  }
  .signout-padding {
    padding: 8px 30px 30px;
  }
  .v-list {
    .v-list-item--active {
      &::before {
        background: $orange;
        opacity: 1;
      }
      .v-list-item__content {
        z-index: 1;
      }
    }
  }
  .loading-process {
    z-index: 999;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.3);
    > div {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
