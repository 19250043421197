<template>
  <div class="promotion-score-management-container">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="promotionList"
      :server-items-length="promotions && promotions.total"
      :footer-props="{
        itemsPerPageOptions,
      }"
      :options.sync="options"
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" :height="checkScreen">
          <v-row class="align-center">
            <v-col cols="12" md="5" lg="6">
              <h1>จัดการคะแนน โปรโมชั่นพิเศษ</h1>
            </v-col>
            <v-col cols="12" md="3" lg="3" class="text-right pr-md-0">
              <v-btn color="#f7941e" @click="createPromotion">
                <v-icon small class="mr-1">mdi-plus</v-icon>สร้างโปรโมชั่นพิเศษ
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" lg="3">
              <v-text-field
                v-model="keyword"
                outlined
                dense
                hide-details
                label="Search"
                append-icon="mdi-magnify"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.id="{ item }">
        {{ $numberWithCommas(item.id) }}
      </template>
      <template v-slot:item.startDate="{ item }">
        <div v-if="item.periodType === 'specific-date'">
          {{ generateDate(item.startDate) }}
        </div>
        <div v-else>
          {{ convertPeroidType(item.periodType) }}
        </div>
      </template>
      <template v-slot:item.endDate="{ item }">
        <div v-if="item.periodType === 'specific-date'">
          {{ generateDate(item.endDate) }}
        </div>
        <div v-else>
          {{ convertPeroidType(item.periodType) }}
        </div>
      </template>
      <template v-slot:item.multiplier="{ item }">
        {{ $numberWithCommas(item.multiplier) }}
      </template>
      <template v-slot:item.edit="{ item }">
        <v-btn class="action-button" depressed small @click="editItem(item)">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn class="action-button" depressed small @click="deleteItem(item)">
          <v-icon small>mdi-trash-can-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <ConfirmModal v-model="conf.status" :data="conf" @confirm="deleteData" />
  </div>
</template>

<script>
import { findIndex } from "lodash";
import moment from "moment";
import Utils from "@/mixin";
import { itemsPerPageOptions } from "@/plugins/common";

import ConfirmModal from "@/components/ConfirmModal";

export default {
  mixins: [Utils],
  components: {
    ConfirmModal,
  },
  computed: {
    promotionList() {
      const { promotions, options } = this;
      const start = (options.page - 1) * options.itemsPerPage + 1;
      const promotionList =
        promotions?.data?.map((row, index) => {
          const list = {
            index: index + start,
            ...row,
          };
          return list;
        }) || [];
      return promotionList;
    },
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return "64px";
      }
      return "218px";
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  data: () => ({
    periodTypeList: [
      { name: "วันเกิด", value: "birth-date" },
      { name: "สัปดาห์เกิด", value: "week-of-birth" },
      { name: "เดือนเกิด", value: "month-of-birth" },
      { name: "เฉพาะวันที่", value: "specific-date" },
    ],
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    headers: [
      { text: "ลำดับ", value: "index", align: "center", sortable: false },
      { text: "ชื่อโปรโมชั่นพิเศษ", value: "name" },
      {
        text: "วันเริ่มต้น",
        value: "startDate",
        sortable: false,
        align: "center",
      },
      {
        text: "วันสิ้นสุด",
        value: "endDate",
        sortable: false,
        align: "center",
      },
      {
        text: "ตัวคูณ",
        value: "multiplier",
        sortable: false,
        align: "center",
      },
      { text: "แก้ไข", value: "edit", sortable: false, align: "center" },
      { text: "ลบ", value: "delete", sortable: false, align: "center" },
    ],
    conf: {
      status: false,
    },
    keyword: "",
    windowWidth: window.innerWidth,
  }),
  methods: {
    async getInitialData() {
      let condition = {};

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      // show all
      if (this.options && this.options.itemsPerPage<0) {
        condition.listType = "all"
      }

      await this.getPromotions(condition);
    },
    generateDate(n) {
      return n !== null ? moment(n).format("DD/MM/YYYY HH:mm:ss") : "-";
    },
    convertPeroidType(n) {
      const { periodTypeList } = this;
      const index = findIndex(periodTypeList, (i) => i.value === n);
      return periodTypeList[index].name;
    },
    createPromotion() {
      this.$router.push({
        name: "PromotionScoreManagement-Editor",
        params: { createPromotion: true },
      });
    },
    editItem(item) {
      this.$router.push({
        name: "PromotionScoreManagement-Editor",
        params: { promotion: item },
      });
    },
    deleteItem(data) {
      this.conf = {
        ...this.conf,
        text: `โปรโมชั่น: ${data?.name}`,
        status: true,
        data,
      };
    },
    async deleteData(value = false) {
      if (value) {
        await this.deletePromotions({ id: this.conf?.data?.id });
      }
      this.conf = {
        ...this.conf,
        status: false,
        data: undefined,
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    keyword: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.promotion-score-management-container {
  padding: 40px 65px;
  .action-button {
    min-width: 28px;
    padding: 0;
  }
}
</style>