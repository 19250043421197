<template>
  <div class="editor-voucher-container">
    <h1 class="mb-4">{{ createVoucher ? "สร้าง" : "แก้ไข" }} Voucher</h1>
    <v-form :disabled="isLoading" @submit.prevent="submitForm">
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>ชื่อบัตรของขวัญ</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="ชื่อบัตรของขวัญ"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.name"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>คะแนนที่ใช้</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="คะแนน"
            outlined
            dense
            color="black"
            background-color="white"
            @keypress="validateNumeric"
            v-model="formData.point"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-2" />
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn
            class="mt-4"
            color="#f7941e"
            type="submit"
            block
            :disabled="isLoading || validate"
          >
            บันทึก
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn class="mt-4" outlined block @click="backVoucher">กลับ</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Utils from "@/mixin";
import { cloneDeep, isEmpty } from "lodash";

export default {
  mixins: [Utils],
  computed: {
    validate() {
      const { name, point } = this.formData;
      if (!(name && point)) return true;
      return false;
    },
  },
  mounted() {
    const { createVoucher, voucher } = this;
    if (!createVoucher) {
      if (isEmpty(voucher)) this.backVoucher();
      const cloneVoucher = cloneDeep(voucher);
      this.formData = {
        id: cloneVoucher?.id || "",
        name: cloneVoucher?.name || "",
        point: cloneVoucher?.point?.toString() || "",
      };
    }
  },
  data: () => ({
    formData: {
      name: "",
      point: "",
    },
    rules: {
      required: (value) => !!value || "Require field.",
    },
  }),
  props: {
    createVoucher: {
      type: Boolean,
      default: false,
    },
    voucher: {
      type: Object,
    },
  },
  methods: {
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    backVoucher() {
      this.$router.push({
        name: "Voucher",
      });
    },
    async submitForm() {
      const { createVoucher, formData } = this;
      if (createVoucher) {
        const submitForm = {
          ...formData,
          point: parseInt(formData.point),
        };
        await this.createVouchers(submitForm);
      } else {
        const submitForm = {
          ...formData,
          point: parseInt(formData.point),
        };
        await this.editVouchers(submitForm);
      }
      this.$router.push({ name: "Voucher" });
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-voucher-container {
  padding: 40px 65px;
}
</style>