var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor-promotion-score-container"},[_c('h1',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.createPromotion ? "สร้าง" : "แก้ไข")+"โปรโมชั่นพิเศษ ")]),_c('v-form',{attrs:{"disabled":_vm.isLoading},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-row',{staticClass:"text-field"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"3","md":"3","lg":"2"}},[_c('label',[_vm._v("ชื่อโปรโมชั่น")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"placeholder":"ชื่อโปรโมชั่น","outlined":"","dense":"","color":"black","background-color":"white","rules":[_vm.rules.required]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1)],1),_c('v-row',{staticClass:"text-field"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"3","md":"3","lg":"2"}},[_c('label',[_vm._v("ประเภท")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('v-combobox',{attrs:{"disabled":!_vm.createPromotion,"items":_vm.periodTypeList,"placeholder":"ประเภท","item-value":"value","item-text":"name","outlined":"","single-line":"","dense":"","color":"black","background-color":!_vm.createPromotion ? '#d0d0d0' : 'white',"rules":[_vm.rules.required]},on:{"change":_vm.changePeriod},model:{value:(_vm.formData.periodType),callback:function ($$v) {_vm.$set(_vm.formData, "periodType", $$v)},expression:"formData.periodType"}})],1)],1),(
        _vm.formData.periodType === '' ||
        _vm.formData.periodType === null ||
        (_vm.formData.periodType && _vm.formData.periodType.value === 'specific-date')
      )?_c('v-row',{staticClass:"text-field mb-6"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"3","md":"3","lg":"2"}},[_c('label',[_vm._v("วันเริ่มต้น")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('DatePicker',{attrs:{"disabled":!_vm.formData.periodType,"text-field-props":_vm.textFieldeProps,"date-picker-props":_vm.colorProps,"time-picker-props":_vm.colorProps,"tab-props":_vm.colorProps,"timeFormat":"HH:mm:ss","placeholder":"วันเริ่มต้น"},model:{value:(_vm.formData.startDate),callback:function ($$v) {_vm.$set(_vm.formData, "startDate", $$v)},expression:"formData.startDate"}})],1)],1):_vm._e(),(
        _vm.formData.periodType === '' ||
        _vm.formData.periodType === null ||
        (_vm.formData.periodType && _vm.formData.periodType.value === 'specific-date')
      )?_c('v-row',{staticClass:"text-field mb-6"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"3","md":"3","lg":"2"}},[_c('label',[_vm._v("วันสิ้นสุด")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('DatePicker',{attrs:{"disabled":!_vm.formData.periodType,"text-field-props":_vm.textFieldeProps,"date-picker-props":_vm.colorProps,"time-picker-props":_vm.colorProps,"tab-props":_vm.colorProps,"timeFormat":"HH:mm:ss","placeholder":"วันสิ้นสุด"},model:{value:(_vm.formData.endDate),callback:function ($$v) {_vm.$set(_vm.formData, "endDate", $$v)},expression:"formData.endDate"}})],1)],1):_vm._e(),_c('v-row',{staticClass:"text-field"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"3","md":"3","lg":"2"}},[_c('label',[_vm._v("ตัวคูณ")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"placeholder":"ตัวคูณคะแนนที่ได้รับต่อใบเสร็จ","outlined":"","dense":"","color":"black","background-color":"white","rules":[_vm.rules.required]},on:{"keypress":_vm.validateNumeric},model:{value:(_vm.formData.multiplier),callback:function ($$v) {_vm.$set(_vm.formData, "multiplier", $$v)},expression:"formData.multiplier"}})],1)],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","lg":"2"}},[_c('v-btn',{staticClass:"mt-4",attrs:{"color":"#f7941e","type":"submit","block":"","disabled":_vm.isLoading || _vm.validate}},[_vm._v(" บันทึก ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","lg":"2"}},[_c('v-btn',{staticClass:"mt-4",attrs:{"outlined":"","block":""},on:{"click":_vm.backPromotionScore}},[_vm._v("กลับ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }