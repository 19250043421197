<template>
  <div class="receipt-container">
    <div class="receipt-section">
      <img :src="require('@/assets/logo.svg')" width="100%" />
      <table class="text-left">
        <tbody>
          <tr>
            <td class="line-higher" colspan="2">วันที่: {{ reportTime }}</td>
          </tr>
          <tr>
            <td class="line-higher" colspan="2">
              สมาชิก เดอะ สตรีท พอยท์ {{ customerName }}
            </td>
          </tr>
          <tr>
            <td class="line-higher" colspan="2">
              เบอร์โทรศัพท์ {{ phoneNumber }}
            </td>
          </tr>
          <tr>
            <td class="line-higher">พอยท์สะสม</td>
            <td class="line-higher text-right">
              {{ $numberWithCommas(totalPoint) }} พอยท์
            </td>
          </tr>
          <tr>
            <td class="line-higher">แลกพอยท์</td>
            <td class="line-higher text-right">
              {{ $numberWithCommas(redeemPoint) }} พอยท์
            </td>
          </tr>
          <tr>
            <td class="line-higher">พอยท์คงเหลือ</td>
            <td class="line-higher text-right">
              {{ $numberWithCommas(remainPoint) }} พอยท์
            </td>
          </tr>
          <tr v-for="(item, index) in voucherList" :key="index">
            <td class="line-higher">{{ item.voucher.name }}</td>
            <td class="line-higher text-right">{{ item.code }}</td>
          </tr>
        </tbody>
      </table>
      <p class="line-higher mb-0">เงื่อนไขการแลกพอยท์</p>
      <ul>
        <li>
          หมายเลขโทรศัพท์จะต้องตรงกับ ชื่อ-สกุล
          และหมายเลขบัตรประชาชนที่ลงทะเบียน สมาชิก เดอะ สตรีท พอยท์ เท่านั้น
        </li>
        <li>พอยท์สะสมที่ใช้แลกบัตรของขวัญแล้วไม่สามารถขอคืนไม่ว่ากรณีใดๆ</li>
        <li>
          สมาชิกได้ตรวจสอบพอยท์สะสม และ พอยท์คงเหลือ ก่อน/หลัง
          การแลกพอยท์เรียบร้อยแล้ว
          บริษัทขอยืนยันข้อมูลพอยท์สะสมและคงเหลือตามเอกสาร
        </li>
        <li>
          การแลกบัตรของขวัญ สามารถออกได้เพียงครั้งเดียวเท่านั้น
          ขอสงวนสิทธิ์ในการงดออกของขวัญใหม่ ทุกกรณี
        </li>
        <li>
          บริษัทฯ สงวนสิทธิ์ในการเปลี่ยนแปลงหรือยกเลิก แก้ไข
          เพิ่มเติมรูปแบบเงื่อนไขการแลกพอยท์ ตามที่เห็นสมควร
          โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
        </li>
      </ul>
      <br /><br />
      <p class="text-overflow-hidden">
        <span class="text-bold">ลงนาม</span
        >.........................................................................................
      </p>
      <br /><br />
      <p class="text-overflow-hidden">
        <span class="text-bold">เจ้าหน้าที่ผู้รับเรื่อง</span
        >................................................................
      </p>
      <br /><br />
    </div>
  </div>
</template>

<script>
import { forEach } from "lodash";
import moment from "moment";

export default {
  computed: {
    customerName() {
      const { customerProfile } = this;
      return `คุณ ${customerProfile?.firstName} ${customerProfile?.lastName}`;
    },
    phoneNumber() {
      const { customerProfile } = this;
      return customerProfile?.phone;
    },
    reportTime() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
    totalPoint() {
      const { customerProfile } = this;
      return `${customerProfile?.totalPoint}`;
    },
    redeemPoint() {
      const { voucherList } = this;
      let total = 0;
      forEach(voucherList, (i) => {
        total += i.voucher.point;
      });
      return `${total}`;
    },
    remainPoint() {
      return `${parseFloat(this.totalPoint) - parseFloat(this.redeemPoint)}`;
    },
  },
  data: () => ({
    customerProfile: {},
    voucherList: [],
  }),
  mounted() {
    this.customerProfile = JSON.parse(
      sessionStorage.getItem("customerProfile")
    );
    this.voucherList = JSON.parse(sessionStorage.getItem("voucherList"));
    window.print();
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "THSarabunNew";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/font/THSarabunNew.ttf") format("truetype");
}
@font-face {
  font-family: "THSarabunNew";
  font-style: normal;
  font-weight: bold;
  src: url("../assets/font/THSarabunNew Bold.ttf") format("truetype");
}
@font-face {
  font-family: "THSarabunNew";
  font-style: italic;
  font-weight: normal;
  src: url("../assets/font/THSarabunNew Italic.ttf") format("truetype");
}
@font-face {
  font-family: "THSarabunNew";
  font-style: italic;
  font-weight: bold;
  src: url("../assets/font/THSarabunNew BoldItalic.ttf") format("truetype");
}

.receipt-section {
  width: 80mm;
  font-size: 0.25em;
  margin: 8px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  border: 1px solid #dddddd;
}
.receipt-container {
  // font-family: "THSarabunNew";
  font-family: Tahoma;
  font-size: 50px;
  font-weight: normal;
  color: black;
  width: 100%;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-overflow-hidden {
  text-overflow: clip;
  white-space: nowrap;
  overflow-x: hidden;
}
.text-bold {
  font-weight: bold;
}
.line-higher {
  line-height: 2em;
}
// ul li {
//   list-style-type: none;
//   &:before {
//     content: "• ";
//     margin-left: 1em;
//   }
// }
</style>