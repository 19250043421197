import { REQUEST_GET, REQUEST_POST } from './API_Request'
import { errorMessage } from '@/plugins/common'

export const state = {}

export const mutations = {
  async GET_DASHBOARD(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET(`/api/reports/power_bi`)
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EXPORT_DASHBOARD(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/points/transaction/xlsx`,
        data?.form,
        { responseType: 'blob' }
      )
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
}

export const actions = {
  getDashboard(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_DASHBOARD(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async exportDashboard(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EXPORT_DASHBOARD(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
}

export const getters = {}

export const reports = {
  state,
  mutations,
  actions,
  getters,
}
