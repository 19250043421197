<template>
  <div class="customer-management-container">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="(users && users.data) || []"
      :server-items-length="users && users.total"
      :footer-props="{
        itemsPerPageOptions,
      }"
      :options.sync="options"
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" :height="checkScreen">
          <v-row class="align-center">
            <v-col cols="6" sm="5" lg="7">
              <h1>จัดการข้อมูลลูกค้า</h1>
            </v-col>
            <v-col cols="6" sm="3" lg="2" class="text-right pr-sm-0">
              <v-btn color="#f7941e" @click="exportData">Export All</v-btn>
            </v-col>
            <v-col cols="12" sm="4" lg="3">
              <v-text-field
                v-model="keyword"
                outlined
                dense
                hide-details
                label="Search"
                append-icon="mdi-magnify"
              />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.id="{ item }">
        <div>{{ ("00000" + item.id).slice(-6) }}</div>
      </template>
      <template v-slot:item.name="{ item }">
        <div>{{ item.firstName + " " + item.lastName }}</div>
      </template>
      <template v-slot:item.registerPath="{ item }">
        <div>{{ item.utm.medium || item.utm.source }}</div>
      </template>
      <template v-slot:item.exportScore="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="action-button"
              depressed
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-file-document-outline</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="exportScore(item, 'Receive')">
              <v-list-item-title>การได้รับคะแนน</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="exportScore(item, 'Deduct')">
              <v-list-item-title>การใช้คะแนน</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.viewDetail="{ item }">
        <v-btn class="action-button" depressed small @click="viewDetail(item)">
          <v-icon small>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

import Utils from "@/mixin";

const itemsPerPageOptions = [25, 50, 100, 250, 500]

export default {
  mixins: [Utils],
  computed: {
    checkScreen() {
      const { windowWidth } = this;
      if (windowWidth >= 960) {
        return "64px";
      }
      return "148px";
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.itemsPerPageOptions = itemsPerPageOptions;
  },
  data: () => ({
    options: {
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    total: 0,
    headers: [
      { text: "รหัสลูกค้า", value: "id", align: "center" },
      { text: "เบอร์โทรศัพท์", value: "phone", sortable: false },
      { text: "ชื่อลูกค้า", value: "name" },
      { text: "อีเมล", value: "email", sortable: false },
      {
        text: "ช่องทางการสมัคร",
        value: "registerPath",
        sortable: false,
        align: "center",
      },
      {
        text: "แคมเปญ",
        value: "utm.campaign",
        sortable: false,
        align: "center",
      },
      {
        text: "รายงานคะแนน",
        value: "exportScore",
        sortable: false,
        align: "center",
      },
      {
        text: "รายละเอียด",
        value: "viewDetail",
        sortable: false,
        align: "center",
      },
    ],
    keyword: "",
    windowWidth: window.innerWidth,
  }),
  methods: {
    async getInitialData() {
      let condition = {
        role: "customer",
      };

      // filter
      if (this.keyword) {
        condition.query = this.keyword;
      }

      // paging
      {
        // Per page
        if (this.options && this.options.itemsPerPage) {
          condition.limit = this.options.itemsPerPage;
        }

        // Page
        if (this.options && this.options.page) {
          condition.page = this.options.page;
        }
      }

      // sort
      if (this.options?.sortBy?.[0]) {
        condition.order = `${this.options.sortBy[0]}:${
          this.options?.sortDesc?.[0] ? "desc" : "asc"
        }`;
      }

      await this.getUsers(condition);
    },
    async exportData() {
      let filename;
      let res;
      res = await this.exportCustomer();
      filename = `Customer_${moment().format("YYMMDD_HHmmss")}.xlsx`;
      fileDownload(res, filename);
    },
    async exportScore(item, type) {
      let filename;
      let res;
      if (type === "Receive") {
        res = await this.exportReceive({ userId: item.id });
        filename = `CustomerPointReceive_${moment().format(
          "YYMMDD_HHmmss"
        )}.xlsx`;
      } else {
        res = await this.exportDeduct({ userId: item.id });
        filename = `CustomerPointDeduct_${moment().format(
          "YYMMDD_HHmmss"
        )}.xlsx`;
      }
      fileDownload(res, filename);
    },
    viewDetail(item) {
      this.$router.push({ name: "CustomerDetail", params: { id: item?.id } });
    },
  },
  watch: {
    options: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
    keyword: {
      handler() {
        this.getInitialData();
      },
      deep: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-management-container {
  padding: 40px 65px;
  .action-button {
    min-width: 28px;
    padding: 0;
  }
}
</style>