import { forEach, size } from 'lodash'
import jwtDecode from 'jwt-decode'

import router from '@/router'

export const getToken = () => localStorage.getItem('token') || sessionStorage.getItem('token')

export const getJWTToken = (token = getToken()) => {
  if (token === null) {
    return null
  } else {
    const jwtToken = jwtDecode(token)
    return jwtToken?.u
  }
}

export const redirectLogin = () => {
  const decode = getJWTToken()
  const { roles } = decode
  if (size(roles) > 0) {
    let adminCheck = false
    forEach(roles, (i) => {
      if (i === PERMISSION.SYSTEM_ADMIN || i === PERMISSION.APP_ADMIN) {
        adminCheck = true
      }
    })

    if (adminCheck) {
      router.push({ name: 'Voucher' })
    } else {
      router.push({ name: 'SearchOperation' })
    }
  }
}

export const PERMISSION = {
  SYSTEM_ADMIN: 'system.admin',
  APP_ADMIN: 'app.admin',
  OPERATION: 'operation',
  CUSTOMER: 'customer',
}
