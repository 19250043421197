<template>
  <div class="customer-profile-container container">
    <v-form :disabled="isLoading" v-model="valid" @submit.prevent="submitForm">
      <h1 class="d-flex mb-4">
        ข้อมูลลูกค้า / Customer Information ({{ code }})
        <div class="ml-auto">
          สถานะ PDPA:
          <span class="green-color" v-if="pdpaStatus === true">Yes</span
          ><span class="red-color" v-else>No</span>
        </div>
      </h1>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="2">
          <div>คำนำหน้า / Title<span class="red--text">*</span></div>
          <v-combobox
            v-model="comboBoxTitle"
            :items="titleItems"
            placeholder="คำนำหน้า / Title"
            item-value="value"
            item-text="name"
            outlined
            dense
            color="black"
            background-color="white"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="3">
          <div>ชื่อ / Name<span class="red--text">*</span></div>
          <v-text-field
            placeholder="ชื่อ / Name"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.firstName"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="3">
          <div>นามสกุล / Last Name<span class="red--text">*</span></div>
          <v-text-field
            placeholder="นามสกุล  / Last Name"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.lastName"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <div>
            เบอร์โทรศัพท์ / Mobile Phone No.<span class="red--text">*</span>
          </div>
          <v-text-field
            placeholder="เบอร์โทรศัพท์ / Mobile Phone No."
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.phone"
            :rules="[rules.required, rules.max]"
            @keypress="validateNumeric"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="5">
          <div>อีเมล / E-mail Address</div>
          <v-text-field
            placeholder="อีเมล / E-mail Address"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.email"
            :rules="[rules.emailValidate]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="7">
          <div>เลขบัตรประชาชน หรือ พาสปอร์ต / ID Card No. or Passport</div>
          <v-text-field
            placeholder="เลขบัตรประชาชน หรือ พาสปอร์ต / ID Card No. or Passport"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.citizenId"
            :rules="[rules.citizenValidate]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" md="4">
          <div>
            วันเดือนปีเกิด / Date of Birth<span class="red--text">*</span>
          </div>
          <BirthDateInput
            placeholder="วันเดือนปีเกิด / Date of Birth"
            format="DD/MM/YYYY"
            persistentPlaceholder
            :value="formData.birthDate"
            @input="updateDateInput"
            @clear="onClear"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <div>ประเทศ / Country</div>
          <v-combobox
            v-model="formData.country"
            :items="countryItems"
            persistent-placeholder
            placeholder="ประเทศ / Country"
            item-value="id"
            item-text="name"
            outlined
            dense
            color="black"
            background-color="white"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <div>ทะเบียนรถ / License Plate No.</div>
          <v-text-field
            placeholder="ทะเบียนรถ / License Plate No."
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.vehicleRegistrationNo"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="6">
          <div>เพศ / Gender<span class="red--text">*</span></div>
          <v-radio-group
            v-model="formData.gender"
            class="mt-0"
            row
            :rules="[rules.required]"
          >
            <v-radio label="ชาย / Men" value="men" />
            <v-radio label="หญิง / Women" value="women" />
            <v-radio label="อื่นๆ" value="other" />
          </v-radio-group>
        </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <div>สถานภาพ / Marital Status</div>
          <v-radio-group v-model="formData.maritalStatus" class="mt-0" row>
            <v-radio label="โสด / Single" :value="false" />
            <v-radio label="สมรส / Married" :value="true" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="4" lg="3">
          <div>คะแนน</div>
          <div class="score-plate">
            {{ $numberWithCommas(this.totalPoint) }}
          </div>
        </v-col>
        <v-col class="expire-point-section" cols="12" sm="auto" offset-sm="1">
          <div v-for="(item, index) in pointExpire" :key="index">
            {{ $numberWithCommas(item.remainPoint) }} คะแนน หมดอายุ
            {{ moment(item.expireDate).format('DD/MM/YYYY') }}
          </div>
        </v-col>
      </v-row>

      <h1 class="mt-4 mb-2">ที่อยู่ลูกค้า / Address</h1>
      <div class="mb-4">ที่อยู่ปัจจุบัน / Current Address</div>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="4">
          <div>บ้านเลขที่ / House No.</div>
          <v-text-field
            placeholder="บ้านเลขที่ / House No."
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formAddress.houseNo"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <div>ซอย / Lane</div>
          <v-text-field
            placeholder="ซอย / Lane"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formAddress.lane"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="4">
          <div>หมู่ / Village No.</div>
          <v-text-field
            placeholder="หมู่ / Village No."
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formAddress.villageNo"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="6">
          <div>ถนน / Road</div>
          <v-text-field
            placeholder="ถนน / Road"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formAddress.road"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="6">
          <div>หมู่บ้าน/อาคาร / Village/Building</div>
          <v-text-field
            placeholder="หมู่บ้าน/อาคาร / Village/Building"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formAddress.village"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="3">
          <div>รหัสไปรษณีย์ / Postal Code<span class="red--text">*</span></div>
          <v-autocomplete
            v-model="formAddress.postcode"
            :items="postalCodeItems"
            persistent-placeholder
            placeholder="00000"
            :item-value="
              (item) => {
                return item
              }
            "
            :item-text="
              (item) => {
                return `${item.postcode} ${item.subDistrict} ${item.district} ${item.province}`
              }
            "
            outlined
            dense
            color="black"
            background-color="white"
            @keypress="validateNumeric"
            @change="inputPostalCode"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="3">
          <div>จังหวัด / Province<span class="red--text">*</span></div>
          <v-autocomplete
            v-model="formAddress.province"
            :items="provinceItems"
            persistent-placeholder
            placeholder="กรุณาเลือก"
            item-value="id"
            item-text="name"
            outlined
            dense
            color="black"
            background-color="white"
            @change="inputProvince"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="3">
          <div>อำเภอ / เขต / District<span class="red--text">*</span></div>
          <v-autocomplete
            v-model="formAddress.district"
            :items="districtItems"
            persistent-placeholder
            placeholder="กรุณาเลือก"
            item-value="id"
            item-text="name"
            outlined
            dense
            color="black"
            background-color="white"
            @change="inputDistrict"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="3">
          <div>ตำบล / แขวง / Sub District<span class="red--text">*</span></div>
          <v-autocomplete
            v-model="formAddress.subDistrict"
            :items="subDistrictItems"
            persistent-placeholder
            placeholder="กรุณาเลือก"
            item-value="id"
            item-text="name"
            outlined
            dense
            color="black"
            background-color="white"
            @change="inputSubDistrict"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="12">
          <div>การศึกษา</div>
          <v-radio-group v-model="formData.education" class="mt-0" row>
            <v-radio label="ประถมศึกษา" value="primary" />
            <v-radio label="มัธยมศึกษา" value="high school" />
            <v-radio label="ปริญญาตรี" value="bachelor" />
            <v-radio label="สูงกว่าปริญญาตรี" value="higher bachelor" />
            <v-radio label="อื่นๆ" value="other" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="py-0" cols="12" sm="12">
          <div>รายได้ต่อครัวเรือน / เดือน</div>
          <v-radio-group v-model="formData.incomeRate" class="mt-0" row>
            <v-radio label="ต่ำกว่า 15,000 บาท" value="<15000" />
            <v-radio label="15,000 - 30,000 บาท" value="15000-30000" />
            <v-radio label="30,001 - 50,000 บาท" value="30001-50000" />
            <v-radio label="50,001 - 70,000 บาท" value="50001-70000" />
            <v-radio label="70,001 - 100,000 บาท" value="70001-100000" />
            <v-radio label="100,000 บาทขึ้นไป" value=">100000" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-divider class="my-2" />
      <div
        class="d-flex align-center check-box-input mt-3"
        v-if="mktConsentDetail !== null"
      >
        <v-checkbox
          class="mt-0 pt-0"
          v-model="formData.mktconsent"
          :ripple="checkAdmin"
          :readonly="!checkAdmin"
          hide-details
        />
        <span>
          ฉันต้องการรับข้อมูลข่าวสารทางการตลาด
          <span class="cursor-pointer primary--text" @click.stop="toggleMarket">
            รายละเอียดการให้ความยินยอม
          </span>
        </span>
      </div>
      <div v-if="consentList !== null">
        <div
          class="d-flex align-center check-box-input mt-2"
          v-for="(item, index) in consentList"
          :key="index"
        >
          <v-checkbox
            class="mt-0 pt-0"
            v-model="item.check"
            :ripple="false"
            readonly
            hide-details
          />
          <span>
            ข้าพเจ้าได้อ่านและยอมรับ
            <span
              class="cursor-pointer primary--text"
              @click.stop="getTermCon(index)"
            >
              ข้อตกลงและเงื่อนไข
            </span>
            สมาชิก เดอะ สตรีท พอยท์
          </span>
        </div>
      </div>
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn
            class="mt-4"
            color="#f7941e"
            type="submit"
            block
            :disabled="isLoading || validate || !valid"
          >
            บันทึก
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn class="mt-4" color="#f7941e" block @click="sendLink">
            ส่ง Link ให้ลูกค้าแก้ไขข้อมูลส่วนตัว
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <h1 class="mt-10 mb-2">ประวัติการทำรายการ</h1>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="historyData"
      :footer-props="{
        itemsPerPageOptions,
      }"
      :options.sync="options"
      :server-items-length="itemsTotal"
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <!-- <template v-slot:item.description="{ item }">
        {{ $numberWithCommasDescription(item.description) }}
      </template> -->
    </v-data-table>
    <TermCon
      :modal="termModal"
      :toggle="toggleTermCon"
      :detail="consentDetail"
    />
    <Market
      :modal="marketModal"
      :toggle="toggleMarket"
      :detail="mktConsentDetail"
    />
    <ConfirmModal
      disabledCancel
      v-model="conf.status"
      :data="conf"
      @confirm="conf = { ...conf, status: false }"
    />
  </div>
</template>

<script>
import { isEmpty, forEach, size } from 'lodash'
import moment from 'moment'
import Utils from '@/mixin'
import { REQUEST_GET } from '@/store/API_Request'
import { getJWTToken, PERMISSION } from '@/plugins/auth'
import { itemsPerPageOptions } from '@/plugins/common'

import Market from './Market.vue'
import TermCon from './TermCon.vue'

import BirthDateInput from '@/components/BirthDateInput'
import ConfirmModal from '@/components/ConfirmModal'

export default {
  mixins: [Utils],
  components: {
    BirthDateInput,
    ConfirmModal,
    Market,
    TermCon,
  },
  watch: {
    options: {
      handler(ne, pe) {
        // Page
        if (ne.page != pe.page || ne.itemsPerPage != pe.itemsPerPage) {
          this._getPointHistory()
        }

        // Sort
        if (
          (ne.sortBy && ne.sortBy[0] && ne.sortBy[0] != pe.sortBy[0]) ||
          (ne.sortDesc &&
            ne.sortDesc[0] != undefined &&
            pe.sortDesc &&
            ne.sortDesc[0] != pe.sortDesc[0])
        ) {
          this._getPointHistory()
        }
      },
      deep: true,
    },
  },
  data: () => ({
    valid: false,
    formData: {
      code: '',
      title: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      citizenId: '',
      birthDate: null,
      country: null,
      vehicleRegistrationNo: '',
      gender: null,
      education: '',
      incomeRate: '',
    },
    formAddress: {
      type: 'current',
      houseNo: '',
      lane: '',
      village: '',
      villageNo: '',
      road: '',
      subDistrictId: null,
      districtId: null,
      provinceId: null,
      postcode: '',
    },
    countryItems: [],
    provinceItems: [],
    districtItems: [],
    subDistrictItems: [],
    postalCodeItems: [],
    titleItems: [
      { value: 'mr', name: 'นาย' },
      { value: 'ms', name: 'นาง' },
      { value: 'mrs', name: 'นางสาว' },
    ],
    rules: {
      required: (value) => !!value || 'Require field.',
      emailValidate: (email) => {
        const re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
        if (isEmpty(email) || re.test(String(email).toLowerCase())) {
          return true
        }
        return 'Invalid format.'
      },
      max: (value) => (!!value && value.length === 10) || 'Invalid format.',
      citizenValidate: (value) => {
        const numberValidate = /^[0-9]+$/ //eslint-disable-line
        if (
          value === '' ||
          (numberValidate.test(value) && value.length === 13)
        ) {
          return true
        }
        return 'Invalid format.'
        // if (numberValidate.test(value)) {
        //   return (!!value && value.length === 13) || "Invalid format.";
        // } else {
        //   return (!!value && value.length <= 13) || "Invalid format.";
        // }
      },
    },
    options: {
      page: 1,
      itemsPerPage: 50,
    },
    itemsPerPageOptions: [],
    headers: [
      { text: 'ลำดับ', value: 'index', sortable: false },
      { text: 'วัน/เดือน/ปี', value: 'date' },
      { text: 'เวลา', value: 'time', sortable: false },
      { text: 'รายละเอียด', value: 'description', sortable: false },
      { text: 'ผู้บันทึกข้อมูล', value: 'actBy', sortable: false },
    ],
    marketModal: false,
    termModal: false,
    consentList: null,
    consentDetail: null,
    mktConsentDetail: null,
    pdpaStatus: false,
    jwtToken: null,
    checkOperation: false,
    conf: {
      status: false,
    },
  }),
  computed: {
    validate() {
      const { title, firstName, lastName, birthDate, phone, gender } =
        this.formData
      const { district, province, postcode } = this.formAddress
      if (
        !(
          title &&
          firstName &&
          lastName &&
          birthDate &&
          phone &&
          postcode &&
          district?.id &&
          province?.id &&
          gender
        )
      )
        return true
      return false
    },
    comboBoxTitle: {
      get() {
        const index = this.titleItems.findIndex(
          (obj) => obj.value === this.formData.title
        )
        if (index >= 0) {
          return this.titleItems[index].name
        }

        return ''
      },
      set(val) {
        this.formData.title = val.value
      },
    },
    historyData() {
      const { customerHistory, options } = this
      let start = (options.page - 1) * options.itemsPerPage + 1
      const history =
        customerHistory?.data?.map((row, index) => {
          const transaction_data = {
            index: index + start,
            type: row.type,
            // date: row.createAt ? moment(row.createAt).add(543, "year").format("DD/MM/YYYY") : "-",
            // time: row.createAt ? moment(row.createAt).add(543, "year").format("HH:mm") : "-",
            date: row.createAt
              ? moment(row.createAt).format('DD/MM/YYYY')
              : '-',
            time: row.createAt ? moment(row.createAt).format('HH:mm') : '-',
            description: 'Unknown Type',
            actBy: row.actBy?.name || '-',
          }
          if (row.refType == 'receipt') {
            transaction_data.description = `เลขบิล : ${
              row.refCode2
            } ได้บิลที่ร้าน : ${
              row.receipt.shop.name
            } ยอดรวม : ${row.receipt.total?.toLocaleString()} คะแนนที่ได้: ${row.receipt.totalPoint?.toLocaleString()}`
          } else if (row.refType == 'campaign') {
            transaction_data.description = `${
              row.campaign.name
            } คะแนนที่ได้: ${row.campaign.rewardPoint?.toLocaleString()}`
          } else if (row.refType == 'voucher') {
            transaction_data.description = `ชื่อรางวัลที่แลก : ${
              row.voucherCode.voucher.name
            } Code ที่ใช้ : ${
              row.refCode2
            } คะแนนที่ใช้ : ${row.amount?.toLocaleString()}`
          } else if (row.refType == 'expire') {
            transaction_data.description = `คะแนนหมดอายุ : ${row.amount?.toLocaleString()} คะแนน`
          }

          return transaction_data
        }) || []
      return history
    },
    code() {
      if (this.customerProfile?.id == null) return '-'
      return ('00000' + this.customerProfile?.id).slice(-6)
    },
    totalPoint() {
      return this.customerProfile?.totalPoint || '0'
    },
    pointExpire() {
      return this.customerProfile?.pointExpireSummaries
    },
    itemsTotal() {
      return this.customerHistory?.total || 0
    },
  },
  methods: {
    moment,
    async getInitialData() {
      this.toggleLoading()
      await this.getCustomerProfile(this.$route.params.id)
      this.getCountry()
      this.getProvince()
      this.getDistrict({})
      this.getSubDistrict({})
      this.getPostcode({})
      this._getPointHistory()

      const { addresses, ...profile } = this.customerProfile

      this.formData = Object.assign({}, this.formData, profile)
      if (size(addresses) > 0) {
        const { district, province, subDistrict, postcode } = addresses?.[0]
        this.formAddress = Object.assign({}, this.formAddress, {
          ...addresses?.[0],
          postcode: {
            postcode,
            subDistrictId: subDistrict?.id,
            subDistrict: subDistrict?.name,
            districtId: district?.id,
            district: district?.name,
            provinceId: province?.id,
            province: province?.name,
          },
        })
      }

      const { jwtToken } = this
      const { roles } = jwtToken
      let adminCheck = false

      if (size(roles) > 0) {
        forEach(roles, (i) => {
          if (
            i === PERMISSION.SYSTEM_ADMIN ||
            (i === PERMISSION.APP_ADMIN && !adminCheck)
          ) {
            // this.getConsent();
            adminCheck = true
          } else if (i === PERMISSION.OPERATION) {
            // this.getOperationConsent();
          }
        })
      }

      this.checkAdmin = adminCheck
      this.getConsent()

      this.toggleLoading()
    },
    _getPointHistory() {
      const options = {
        params: { id: this.$route.params.id },
        query: {
          page: this.options?.page || 1,
          limit: this.options?.itemsPerPage || 50,
        },
      }
      if (this.options && this.options.sortBy && this.options.sortBy[0]) {
        let sort = 'asc'
        if (this.options.sortDesc && this.options.sortDesc[0]) {
          sort = 'desc'
        }
        let field = this.options.sortBy[0]
        if (field && sort) {
          switch (field) {
            case 'date':
              field = 'createdAt'
              break
            default:
              field = 'createdAt'
              break
          }

          options.query.order = `${field}:${sort}`
        }
      }

      this.getCustomerHistory(options)
    },
    async getOperationConsent() {
      const checkConsent = await this.checkUserConsent({
        userId: this.$route.params.id,
      })
      if (size(checkConsent?.data) === 0) this.pdpaStatus = true
    },
    async getConsent() {
      const checkConsent = await this.checkUserConsent({
        userId: this.$route.params.id,
      })
      if (size(checkConsent?.data) === 0) this.pdpaStatus = true
      const checkMktConsent = await this.checkByUserMktConsent({
        userId: this.$route.params.id,
      })
      if (checkMktConsent?.data === null) this.formData.mktconsent = true

      const consent = await this.getUserConsent()
      let newConsent = []
      forEach(consent?.data, (i) => {
        if (size(checkConsent?.data) > 0) {
          forEach(checkConsent?.data, (j) => {
            if (i.id === j.id) newConsent.push({ ...i, check: false })
            else newConsent.push({ ...i, check: true })
          })
        } else {
          newConsent.push({ ...i, check: true })
        }
      })
      this.consentList = newConsent
      const mktConsent = await this.getMktConsent()
      this.mktConsentDetail = mktConsent?.data
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode >= 48 && charCode <= 57) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    updateDateInput(e) {
      this.formData.birthDate = e
    },
    onClear() {
      this.formData.birthDate = null
    },
    async sendLink() {
      await this.sendCustomerLink(parseInt(this.formData.id))
    },
    async submitForm() {
      const { formData, formAddress } = this

      let submitForm = {
        id: parseInt(formData.id),
        title: formData.title,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        birthDate: formData.birthDate,
        citizenId: formData.citizenId,
        gender: formData.gender,
        vehicleRegistrationNo: formData.vehicleRegistrationNo,
        countryId: formData.country?.id,
        education: formData.education,
        incomeRate: formData.incomeRate,
        maritalStatus: formData.maritalStatus,
        address: {
          type: 'current',
          houseNo: formAddress.houseNo,
          lane: formAddress.lane,
          village: formAddress.village,
          villageNo: formAddress.villageNo,
          road: formAddress.road,
          subDistrictId: formAddress.subDistrict?.id,
          districtId: formAddress.district?.id,
          provinceId: formAddress.province?.id,
          postcode: formAddress.postcode?.postcode,
        },
      }

      const { jwtToken } = this
      const { roles } = jwtToken
      let adminCheck = false

      if (size(roles) > 0) {
        forEach(roles, (i) => {
          if (
            i === PERMISSION.SYSTEM_ADMIN ||
            (i === PERMISSION.APP_ADMIN && !adminCheck)
          ) {
            submitForm = {
              ...submitForm,
              mktconsent: formData.mktconsent || false,
            }
            adminCheck = true
          }
        })
      }

      const res = await this.updateCustomerProfile(submitForm)
      let text = ''
      switch (res) {
        case 'Duplicate email':
          text = 'อีเมล์นี้เป็นสมาชิก The Street Point ในระบบแล้ว'
          break
        case 'Duplicate phone number':
          text = 'หมายเลขนี้เป็นสมาชิก The Street Point ในระบบแล้ว'
          break
        case 'Invalid data':
          text = 'กรุณากรอกข้อมูลให้ถูกต้อง'
          break
        default:
          break
      }
      if (text !== '') {
        this.conf = {
          ...this.conf,
          status: true,
          title: 'แจ้งเตือน!',
          text,
        }
      }
    },
    async inputProvince() {
      const { formAddress } = this
      await this.getPostcode({ provinceId: formAddress?.province })
      await this.getDistrict({ provinceId: formAddress?.province })
      await this.getSubDistrict({ provinceId: formAddress?.province })
      this.formAddress = Object.assign({}, this.formAddress, {
        district: '',
        subDistrict: '',
        postcode: '',
      })
    },
    async inputDistrict() {
      const { formAddress } = this
      await this.getPostcode({
        provinceId: formAddress?.province,
        districtId: formAddress?.district,
      })
      await this.getSubDistrict({
        provinceId: formAddress?.province,
        districtId: formAddress?.district,
      })
      this.formAddress = Object.assign({}, this.formAddress, {
        subDistrict: '',
        postcode: '',
      })
    },
    async inputSubDistrict() {
      const { formAddress } = this
      await this.getPostcode({
        provinceId: formAddress?.province,
        districtId: formAddress?.district,
        subDistrictId: formAddress?.subDistrict,
      })
      this.formAddress = Object.assign({}, this.formAddress, {
        postcode: '',
      })
    },
    async inputPostalCode() {
      const { postcode } = this.formAddress
      this.formAddress = Object.assign({}, this.formAddress, {
        province: { name: postcode.province, id: postcode.provinceId },
        district: { name: postcode.district, id: postcode.districtId },
        subDistrict: { name: postcode.subDistrict, id: postcode.subDistrictId },
      })
    },
    async getCountry() {
      try {
        const res = await REQUEST_GET('/api/countries?listType=all')
        this.countryItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getProvince() {
      try {
        const res = await REQUEST_GET('/api/provinces?listType=all')
        this.provinceItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getDistrict({ provinceId = null }) {
      try {
        const res = await REQUEST_GET(
          `/api/districts?listType=all${
            provinceId !== null ? `&provinceId=${provinceId}` : ''
          }`
        )
        this.districtItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getSubDistrict({ provinceId = null, districtId = null }) {
      try {
        const res = await REQUEST_GET(
          `/api/sub-districts?listType=all${
            provinceId !== null ? `&provinceId=${provinceId}` : ''
          }${districtId !== null ? `&districtId=${districtId}` : ''}`
        )
        this.subDistrictItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async getPostcode({
      provinceId = null,
      districtId = null,
      subDistrictId = null,
      query = null,
    }) {
      try {
        const res = await REQUEST_GET(
          `/api/postcodes?listType=all${
            provinceId !== null ? `&provinceId=${provinceId}` : ''
          }${districtId !== null ? `&districtId=${districtId}` : ''}${
            subDistrictId !== null ? `&subDistrictId=${subDistrictId}` : ''
          }${query !== null ? `&query=${query}` : ''}`
        )
        this.postalCodeItems = res.data.data
      } catch (e) {
        console.log(e)
      }
    },
    getTermCon(index) {
      const { consentList } = this
      this.consentDetail = consentList[index]
      this.toggleTermCon()
    },
    toggleTermCon() {
      this.termModal = !this.termModal
    },
    toggleMarket() {
      this.marketModal = !this.marketModal
    },
  },
  mounted() {
    this.jwtToken = getJWTToken()
    this.itemsPerPageOptions = itemsPerPageOptions
    this.getInitialData()
  },
}
</script>

<style lang="scss" scoped>
$black: #333;
$grey: rgba(0, 0, 0, 0.6);
$green: #4caf50;
$red: #ff5252;
$white: #fff;

.customer-profile-container {
  .green-color {
    color: $green;
  }
  .red-color {
    color: $red;
  }
  .score-plate {
    border: 1px solid $black;
    font-size: 48pt;
    line-height: 27pt;
    min-width: 200px;
    text-align: center;
    color: $green;
    padding: 16px;
    border-radius: 16px;
    background-color: $white;
  }
  .expire-point-section {
    padding: 0 12px;
    align-self: center;
  }
  .check-box-input {
    color: $grey;
    font-size: 16px;
    .cursor-pointer {
      cursor: pointer;
    }
  }
}
</style>
