<template>
  <div class="editor-shop-score-container">
    <h1 class="mb-4">{{ createShop ? "สร้าง" : "แก้ไข" }}คะแนนรายร้านค้า</h1>
    <v-form :disabled="isLoading" v-model="valid" @submit.prevent="submitForm">
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>รหัสร้านค้า</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="รหัสร้านค้า"
            outlined
            dense
            color="black"
            :background-color="'#d0d0d0'"
            disabled
            v-model="formData.shopCode"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>ชื่อร้านค้า</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-combobox
            v-model="formData.shopName"
            :items="shopList"
            placeholder="ชื่อร้านค้า"
            item-value="id"
            item-text="name"
            outlined
            single-line
            dense
            color="black"
            :background-color="createShop ? 'white' : '#d0d0d0'"
            :disabled="!createShop"
            :rules="[rules.required]"
            @change="updateShopCode"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>จำนวนบาท</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="จำนวนบาท"
            outlined
            dense
            color="black"
            background-color="white"
            @keypress="validateNumeric"
            v-model="formData.amountPerPoint"
            :rules="[rules.required, rules.min]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>คะแนน</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="คะแนน"
            outlined
            dense
            disabled
            color="black"
            background-color="#d0d0d0"
            @keypress="validateNumeric"
            v-model="formData.point"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-2" />
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn
            class="mt-4"
            color="#f7941e"
            type="submit"
            block
            :disabled="isLoading || validate || !valid"
          >
            บันทึก
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn class="mt-4" outlined block @click="backShopScore">กลับ</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { cloneDeep, forEach, isEmpty, reduce, size } from "lodash";

import Utils from "@/mixin";

export default {
  mixins: [Utils],
  computed: {
    validate() {
      const { shopId, shopName, amountPerPoint, point } = this.formData;
      if (!(shopId && shopName && amountPerPoint && point)) return true;
      return false;
    },
    shopList() {
      const { shops, points } = this;
      let result = [];
      if (size(shops) > 0) {
        const duplicateList = reduce(
          shops?.data,
          (a, b) => {
            forEach(points?.data, (i) => {
              if (b.id === i.shopId) a.push(b);
            });
            return a;
          },
          []
        );
        const duplicateSet = new Set(duplicateList);
        result = shops?.data.filter((shop) => {
          return !duplicateSet.has(shop);
        });
      }
      return result;
    },
  },
  mounted() {
    const { createShop, shop } = this;
    if (!createShop) {
      if (isEmpty(shop)) this.backShopScore();
      const cloneShop = cloneDeep(shop);
      this.formData = {
        shopCode: cloneShop?.shop?.code?.toString() || "",
        shopId: cloneShop?.shopId || "",
        shopName: cloneShop?.shop?.name || "",
        amountPerPoint: cloneShop?.amount_per_point?.toString() || "",
        point: cloneShop?.point?.toString() || 1,
      };
    } else {
      this.getInitialData();
    }
  },
  data: () => ({
    valid: false,
    formData: {
      shopCode: "",
      shopId: "",
      shopName: "",
      amountPerPoint: "",
      point: 1,
    },
    rules: {
      required: (value) => !!value || "Require field.",
      min: (value) => value > 0 || "Value must be more than 0.",
    },
  }),
  props: {
    createShop: {
      type: Boolean,
      default: false,
    },
    shop: {
      type: Object,
    },
  },
  methods: {
    async getInitialData() {
      let condition = {
        limit: 100,
        listType: "all",
      };

      await this.getShops(condition);
      await this.getPoints(condition);
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    updateShopCode() {
      const { shopName } = this.formData;
      this.formData = {
        ...this.formData,
        shopId: shopName?.id,
        shopCode: shopName?.code,
      };
    },
    backShopScore() {
      this.$router.push({
        name: "ShopScoreManagement",
      });
    },
    async submitForm() {
      const { formData } = this;
      const submitForm = {
        ...formData,
        amountPerPoint: parseInt(formData.amountPerPoint),
      };
      const res = await this.updatePoints(submitForm);
      const { status } = res;
      if (status === 200) {
        this.backShopScore();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-shop-score-container {
  padding: 40px 65px;
}
</style>