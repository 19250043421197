<template>
  <div class="container">
    <h1 class="d-flex mb-4">
      ชื่อลูกค้า {{ customerName }}
      <div class="ml-auto">
        สถานะ PDPA:
        <span class="green-color" v-if="pdpaStatus === true">Yes</span
        ><span class="red-color" v-else>No</span>
      </div>
    </h1>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="(receipts && receipts.data) || []"
      :footer-props="{
        itemsPerPageOptions,
      }"
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <template v-slot:item.total="{ item }">
        {{ $numberWithCommas(item.total) }}
      </template>
      <template v-slot:item.totalPoint="{ item }">
        {{ $numberWithCommas(item.totalPoint) }}
      </template>
    </v-data-table>
    <div v-show="receipts && receipts.total > 0" class="pa-4">
      <v-row class="text-field">
        <v-checkbox v-model="hasCar" :label="`ลูกค้ามีรถมา`"></v-checkbox>
      </v-row>
    </div>
    <div v-show="receipts && receipts.total > 0">
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn color="#f7941e" @click="submitSave()" block> บันทึก </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn type="submit" outlined @click="onCancel()" block>
            ยกเลิก
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-divider class="my-4" />
    <form :disabled="isLoading" @submit.prevent="submitAddReceipt">
      <v-row class="mt-4">
        <v-col class="py-0" cols="12" sm="3">
          <div>รหัสร้าน</div>
          <v-autocomplete
            ref="shop"
            v-model="formData.shopId"
            :items="shops.data"
            placeholder="รหัสร้าน"
            item-value="id"
            :item-text="
              (item) => {
                return `${item.code}-${item.name}`;
              }
            "
            outlined
            single-line
            dense
            color="black"
            background-color="white"
            @change="updateShopName"
            @click="focusShop"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="3">
          <div>ชื่อร้าน</div>
          <v-combobox
            v-model="formData.shopName"
            :items="shops.data"
            disabled
            placeholder="ชื่อร้าน"
            item-value="id"
            item-text="name"
            outlined
            single-line
            dense
            color="black"
            background-color="#d0d0d0"
            @change="updateShopCode"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="3">
          <div>เลขบิล</div>
          <v-text-field
            placeholder="เลขบิล"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.receiptNo"
          />
        </v-col>
        <v-col class="py-0" cols="12" sm="3">
          <div>ยอดรวม (รวม Vat)</div>
          <v-text-field
            placeholder="0.00"
            outlined
            dense
            color="black"
            background-color="white"
            @keypress="validateNumeric"
            v-model="formData.amount"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn color="#f7941e" type="submit" block> เพิ่ม </v-btn>
        </v-col>
      </v-row>
    </form>
    <ConfirmModal v-model="conf.status" :data="conf" @confirm="submitCancel" />
  </div>
</template>

<script>
import { findIndex, size } from "lodash";
import Utils from "@/mixin";

import { itemsPerPageOptions } from "@/plugins/common";

import ConfirmModal from "@/components/ConfirmModal";

export default {
  mixins: [Utils],
  components: {
    ConfirmModal,
  },
  data: () => ({
    hasCar: false,
    itemsPerPageOptions: [],
    headers: [
      { text: "เลขบิล", value: "receiptNo" },
      { text: "ชื่อร้าน", value: "shop.name" },
      { text: "ยอดรวม(รวม Vat)", value: "total", sortable: false },
      { text: "คะแนน", value: "totalPoint", sortable: false },
    ],
    conf: {
      status: false,
    },
    formData: {
      shopId: "",
      shopName: "",
      receiptNo: "",
      amount: undefined,
    },
    pdpaStatus: false,
  }),
  computed: {
    customerName() {
      const { customerProfile } = this;
      if (customerProfile == null) return "-";

      const title_map = {
        mr: "นาย",
        ms: "นาง",
        mrs: "นางสาว",
      };

      const title = title_map[customerProfile.title];

      return `${title} ${customerProfile.firstName} ${customerProfile.lastName}`;
    },
  },
  destroyed() {
    this.clearReceipt();
  },
  methods: {
    async getInitialData() {
      this.getInitialShop();
      await this.getCustomerProfile(this.$route.params.id);

      const checkConsent = await this.checkUserConsent({
        userId: this.$route.params.id,
      });
      if (size(checkConsent?.data) === 0) this.pdpaStatus = true;
    },
    async getInitialShop(keyword = "") {
      let condition = {
        query_shop: keyword,
        isLoading: false,
        listType: "all",
      };

      await this.getShops(condition);
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode >= 48 && charCode <= 57) || charCode === 46 || charCode === 13) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    updateShopName() {
      const { data } = this.shops;
      const { shopId } = this.formData;
      const index = findIndex(data, (i) => i.id === shopId);
      this.formData = {
        ...this.formData,
        shopName: data[index],
      };
    },
    updateShopCode() {
      const { shopName } = this.formData;
      this.formData = {
        ...this.formData,
        shopId: shopName?.id,
      };
    },
    async submitAddReceipt() {
      const submitForm = {
        userId: this.customerProfile.id,
        shopId: this.formData.shopId,
        shopName: this.formData.shopName,
        receiptNo: this.formData.receiptNo,
        amount: parseFloat(this.formData.amount),
      };
      await this.addReceipt(submitForm);
      this.clearData();
      this.focusShop();
    },
    async submitSave() {
      const submitForm = {
        userId: this.customerProfile.id,
      };
      await this.saveReceipt(submitForm);
      this.clearData();
    },
    onCancel() {
      this.conf = {
        ...this.conf,
        title: "ยืนยันการยกเลิก",
        // text: `ยกเลิกรายการ`,
        status: true,
      };
    },
    submitCancel() {
      this.clearReceipt();
      this.conf = {
        ...this.conf,
        status: false,
      };
    },
    clearData() {
      this.formData = {
        shopId: "",
        shopName: "",
        receiptNo: "",
        amount: undefined,
      };
    },
    focusShop() {
      this.$refs.shop.focus();
      this.$refs.shop.activateMenu();
    },
  },
  mounted() {
    this.getInitialData();
    this.itemsPerPageOptions = itemsPerPageOptions;
    this.focusShop();
  },
};
</script>

<style lang="scss" scoped>
$green: #4caf50;
$red: #ff5252;

.green-color {
  color: $green;
}
.red-color {
  color: $red;
}
</style>