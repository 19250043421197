<template>
  <div></div>
</template>

<script>
import { getJWTToken, PERMISSION } from "@/plugins/auth";
import { forEach, size } from "lodash";

export default {
  mounted() {
    const jwtToken = getJWTToken();
    const { roles } = jwtToken;

    let adminCheck = false;
    if (size(roles) > 0) {
      forEach(roles, (i) => {
        if (
          i === PERMISSION.SYSTEM_ADMIN ||
          i === PERMISSION.APP_ADMIN
        ) {
          adminCheck = true;
        }
      });
    }

    if(adminCheck){
      this.$router.push({ name: "Voucher" });
    }else{
      this.$router.push({ name: "SearchOperation" });
    }

    console.log("HOME",{
      jwtToken,
      d:PERMISSION,
      roles,
    })
  },
};
</script>