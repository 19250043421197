export const GLOBAL_MESSAGE = {
  ERROR: 'Something when wrong!',
  CREATE: {
    SUCCESS: 'Create Successfully',
    ERROR: 'Create Failed',
  },
  EDIT: {
    SUCCESS: 'Edit Successfully',
    ERROR: 'Edit Failed',
  },
  DELETE: {
    SUCCESS: 'Delete Successfully',
    ERROR: 'Delete Failed',
  },
  UPDATE: {
    SUCCESS: 'Update Successfully',
    ERROR: 'Update Failed',
  },
}

export const LOGIN_MESSAGE = {
  SUCCESS: 'Login Successfully',
  ERROR: 'Login Failed',
}

export const REGISTER_MESSAGE = {
  SUCCESS: 'Register Successfully',
  ERROR: 'Register Failed',
}

export const FORGOT_PASSWORD_MESSAGE = {
  SUCCESS: 'Forgot Password Successfully',
  ERROR: 'Forgot Password Failed',
}

export const RESET_PASSWORD_MESSAGE = {
  SUCCESS: 'Reset Password Successfully',
  ERROR: 'Reset Password Failed',
}

export const CHANGE_PASSWORD_MESSAGE = {
  SUCCESS: 'Change Password Successfully',
  ERROR: 'Change Password Failed',
}

export const SEND_LINK_MESSAGE = {
  SUCCESS: 'Send Link Successfully',
  ERROR: 'Send Link Failed',
}
