import Vue from 'vue'
import Vuex from 'vuex'

import { authentication } from './Auth'
import { points } from './Point'
import { promotions } from './Promotion'
import { reports } from './Report'
import { shops } from './Shop'
import { vouchers } from './Voucher'
import { users } from './User'
import { customer } from './Customer'
import { receipts } from './Receipt'

Vue.use(Vuex)

export const state = {
  isLoading: false,
  alertMessage: undefined,
}

export const mutations = {
  TOGGLE_LOADING(state) {
    state.isLoading = !state.isLoading
    return state
  },
  TOGGLE_ALERT_MESSAGE(state, alertForm) {
    state.alertMessage = alertForm
    return state
  },
}

export const actions = {
  toggleLoading(context) {
    context.commit('TOGGLE_LOADING')
  },
  toggleAlertMessage(context, formData) {
    context.commit('TOGGLE_ALERT_MESSAGE', formData)
  },
}

export const getters = {
  isLoading(state) {
    return state.isLoading
  },
  alert(state) {
    return state.alertMessage
  },
}

export const modules = {
  authentication,
  points,
  promotions,
  reports,
  shops,
  vouchers,
  users,
  receipts,
  customer,
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules,
})
