<template>
  <div class="reset-password-container">
    <v-toolbar flat height="90" class="reset-password-header-wrapper">
      <v-spacer />
      <router-link :to="'/'">
        <v-img class="home-logo" :src="require('@/assets/logo.svg')" contain />
      </router-link>
      <v-spacer />
      <div class="reset-password-header-button">
        <v-btn class="mr-2" text>Operation</v-btn>
        <v-btn dark>Administrator</v-btn>
      </div>
    </v-toolbar>
    <h1 class="reset-password-title">ลืมรหัสผ่าน</h1>
    <div class="reset-password-wrapper">
      <v-row class="justify-center">
        <v-col cols="10" sm="8" md="4" lg="3">
          <v-card elevation="0">
            <v-form
              :disabled="isLoading"
              v-model="valid"
              :lazy-validation="false"
              @submit.prevent="submitForm"
            >
              <v-text-field
                label="Password"
                id="password"
                :type="show ? 'text' : 'password'"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                :rules="[rules.required]"
                v-model="password"
              />
              <v-text-field
                label="Confirm Password"
                id="confirm-password"
                :type="showConfirm ? 'text' : 'password'"
                :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showConfirm = !showConfirm"
                :rules="[rules.required]"
                v-model="confirmPassword"
              />
              <v-btn
                outlined
                rounded
                large
                block
                type="submit"
                :disabled="isLoading || !valid || checkInput"
                >ยืนยัน</v-btn
              >
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Utils from "@/mixin";

import { SetRedirectURL } from "@/plugins/common";

export default {
  mixins: [Utils],
  async created() {
    await localStorage.removeItem("token");
    await sessionStorage.removeItem("token");
    await this.checkHash();
  },
  computed: {
    checkInput() {
      const { confirmPassword, password } = this;
      return confirmPassword === password ? false : true;
    },
  },
  data: () => ({
    valid: false,
    show: false,
    showConfirm: false,
    confirmPassword: "",
    password: "",
    rules: {
      required: (value) => !!value || "Require field.",
    },
    token: "",
    query: {},
  }),
  methods: {
    async checkHash() {
      // replace url pathname
      const { origin, pathname } = window.location;
      const aLink = document.createElement("a");
      aLink.href = origin + pathname;
      const url = new URL(aLink);

      const redirect = SetRedirectURL(
        this.$route.hash,
        encodeURIComponent(this.$route.fullPath)
      );
      if (redirect.token) {
        this.token = redirect.token;
        this.query = {
          code: this.$route.query.code,
          ref: this.$route.query.ref,
        };
        await this.appAccess(this.token);
        // replace url pathname
        window.history.pushState({}, "", url);
      } else {
        window.location = redirect.url;
      }
    },
    async submitForm() {
      const formData = {
        confirmPassword: this.confirmPassword,
        newPassword: this.password,
        code: this.query?.code,
        ref: this.query?.ref,
      };
      this.resetPassword(formData);
    },
  },
};
</script>

<style lang="scss" scoped>
$black: rgba(0, 0, 0, 0.2);

.reset-password-container {
  background: url("../assets/bg.svg");
  background-size: cover;
  text-align: center;
  height: 100vh;
  position: relative;
  .reset-password-header-wrapper {
    border-bottom: 1px solid $black;
    position: relative;
    .reset-password-header-button {
      display: none;
      @media (min-width: 768px) {
        display: block;
        position: absolute;
        right: 2%;
      }
    }
  }
  .reset-password-title {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
  }
  .reset-password-wrapper {
    display: flex;
    align-items: center;
    height: calc(100vh - 90px);
  }
}
</style>