<template>
  <div class="editor-promotion-score-container">
    <h1 class="mb-4">
      {{ createPromotion ? "สร้าง" : "แก้ไข" }}โปรโมชั่นพิเศษ
    </h1>
    <v-form :disabled="isLoading" @submit.prevent="submitForm">
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>ชื่อโปรโมชั่น</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="ชื่อโปรโมชั่น"
            outlined
            dense
            color="black"
            background-color="white"
            v-model="formData.name"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>ประเภท</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-combobox
            :disabled="!createPromotion"
            v-model="formData.periodType"
            :items="periodTypeList"
            placeholder="ประเภท"
            item-value="value"
            item-text="name"
            outlined
            single-line
            dense
            color="black"
            :background-color="!createPromotion ? '#d0d0d0' : 'white'"
            :rules="[rules.required]"
            @change="changePeriod"
          />
        </v-col>
      </v-row>
      <v-row
        class="text-field mb-6"
        v-if="
          formData.periodType === '' ||
          formData.periodType === null ||
          (formData.periodType && formData.periodType.value === 'specific-date')
        "
      >
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>วันเริ่มต้น</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <DatePicker
            :disabled="!formData.periodType"
            :text-field-props="textFieldeProps"
            :date-picker-props="colorProps"
            :time-picker-props="colorProps"
            :tab-props="colorProps"
            timeFormat="HH:mm:ss"
            placeholder="วันเริ่มต้น"
            v-model="formData.startDate"
          />
        </v-col>
      </v-row>
      <v-row
        class="text-field mb-6"
        v-if="
          formData.periodType === '' ||
          formData.periodType === null ||
          (formData.periodType && formData.periodType.value === 'specific-date')
        "
      >
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>วันสิ้นสุด</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <DatePicker
            :disabled="!formData.periodType"
            :text-field-props="textFieldeProps"
            :date-picker-props="colorProps"
            :time-picker-props="colorProps"
            :tab-props="colorProps"
            timeFormat="HH:mm:ss"
            placeholder="วันสิ้นสุด"
            v-model="formData.endDate"
          />
        </v-col>
      </v-row>
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>ตัวคูณ</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="ตัวคูณคะแนนที่ได้รับต่อใบเสร็จ"
            outlined
            dense
            color="black"
            background-color="white"
            @keypress="validateNumeric"
            v-model="formData.multiplier"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-2" />
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn
            class="mt-4"
            color="#f7941e"
            type="submit"
            block
            :disabled="isLoading || validate"
          >
            บันทึก
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn class="mt-4" outlined block @click="backPromotionScore"
            >กลับ</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import moment from "moment";
import { cloneDeep, findIndex, isEmpty } from "lodash";

import Utils from "@/mixin";

import DatePicker from "@/components/DatePicker";

export default {
  mixins: [Utils],
  components: {
    DatePicker,
  },
  computed: {
    validate() {
      const { name, periodType, multiplier, startDate, endDate } =
        this.formData;
      if (
        !(
          (name &&
            periodType &&
            multiplier &&
            periodType?.value !== "specific-date") ||
          (name &&
            periodType &&
            multiplier &&
            periodType?.value === "specific-date" &&
            startDate &&
            endDate)
        )
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    const { createPromotion, promotion, periodTypeList } = this;
    if (!createPromotion) {
      if (isEmpty(promotion)) this.backPromotionScore();
      const clonePromotion = cloneDeep(promotion);
      const index = findIndex(
        periodTypeList,
        (i) => i.value === clonePromotion?.periodType
      );
      this.formData = {
        id: clonePromotion?.id || "",
        name: clonePromotion?.name || "",
        periodType: periodTypeList[index] || "",
        multiplier: clonePromotion?.multiplier || "",
        startDate:
          clonePromotion?.startDate !== null
            ? new Date(clonePromotion?.startDate)
            : "",
        endDate:
          clonePromotion?.startDate !== null
            ? new Date(clonePromotion?.endDate)
            : "",
      };
    }
  },
  data: () => ({
    periodTypeList: [
      { name: "วันเกิด", value: "birth-date" },
      { name: "สัปดาห์เกิด", value: "week-of-birth" },
      { name: "เดือนเกิด", value: "month-of-birth" },
      { name: "เฉพาะวันที่", value: "specific-date" },
    ],
    formData: {
      name: "",
      periodType: "",
      multiplier: "",
    },
    rules: {
      required: (value) => !!value || "Require field.",
    },
    textFieldeProps: {
      outlined: true,
      dense: true,
      hideDetails: true,
      color: "black",
      backgroundColor: "white",
    },
    colorProps: {
      color: "#f7941e",
    },
  }),
  props: {
    createPromotion: {
      type: Boolean,
      default: false,
    },
    promotion: {
      type: Object,
    },
  },
  methods: {
    clearDate() {
      this.formData = {
        ...this.formData,
        startDate: null,
        endDate: null,
      };
    },
    changePeriod() {
      this.clearDate();
    },
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    backPromotionScore() {
      this.$router.push({
        name: "PromotionScoreManagement",
      });
    },
    async submitForm() {
      const { createPromotion, formData } = this;
      let submitForm = {
        ...formData,
        criteriaType: "all",
        periodType: formData?.periodType?.value,
        multiplier: parseFloat(formData?.multiplier),
      };
      if (formData?.periodType?.value === "specific-date") {
        submitForm = {
          ...submitForm,
          startDate: moment(formData?.startDate).format(),
          endDate: moment(formData?.endDate).format(),
        };
      } else {
        submitForm = {
          ...submitForm,
          startDate: null,
          endDate: null,
        };
      }
      if (createPromotion) {
        const res = await this.createPromotions(submitForm);
        const { status } = res;
        if (status === 201) {
          this.backPromotionScore();
        }
      } else {
        const res = await this.editPromotions(submitForm);
        const { status } = res;
        if (status === 200) {
          this.backPromotionScore();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-promotion-score-container {
  padding: 40px 65px;
}
</style>