<template>
  <div class="operation-container">
      <v-row class="text-field">
          <v-col class="pall-0" cols="12" sm="10">
              <div>รหัสลูกค้า</div>
              <form @submit.prevent="submitCode">
                <v-row class="text-field">
                    <v-col class="px-0" cols="12" sm="9">
                      <v-text-field
                          placeholder="000001"
                          outlined
                          dense
                          color="black"
                          background-color="white"
                          v-model="code"
                          :error-messages="code_errors"
                      />
                    </v-col>
                    <v-col  cols="12" sm="3">
                      <v-btn
                        color="#f7941e"
                        type="submit"
                      >
                        ตกลง
                      </v-btn>
                    </v-col>
                </v-row>
              </form>
          </v-col>
      </v-row>
      <v-row class="text-field">
          <v-col class="pall-0" cols="12" sm="10">
              <div>เบอร์โทรศัพท์ลูกค้า</div>
              <form @submit.prevent="submitTel">
                <v-row class="text-field">
                    <v-col class="px-0" cols="12" sm="9">
                      <v-text-field
                          placeholder="0XX-XXX-XXXX"
                          outlined
                          dense
                          color="black"
                          background-color="white"
                          v-model="tel"
                          :error-messages="tel_errors"
                      />
                    </v-col>
                    <v-col  cols="12" sm="3">
                      <v-btn
                        color="#f7941e"
                        type="submit"
                      >
                        ตกลง
                      </v-btn>
                    </v-col>
                </v-row>
              </form>
          </v-col>
      </v-row>
  </div>
</template>

<script>
import {REQUEST_GET} from '../../store/API_Request'

export default {
  data: () => ({
    code:"",
    tel:"",
    code_errors:"",
    tel_errors:"",
  }),
  methods:{
    async submitCode() {
      const { code } = this;
      if(code.length!=6){
        this.code_errors = "รหัสลูกค้าไม่ถูกต้อง"
        return;
      }

      try {
        let res = await REQUEST_GET(`/api/users/customers/search-code/${code}`)
        if(res.status==200){
          this.$router.push({
            name: "CustomerProfile",
            params: { id: res.data?.id },
          });
        }else{
          this.code_errors = "ไม่พบรหัสลูกค้านี้ในระบบ"
        }

      }catch(e){
        this.code_errors = "ไม่พบรหัสลูกค้านี้ในระบบ"
      }

      
    },
    async submitTel() {
      const {tel} = this;
      if(!/^\d+$/.test(tel)){
        this.tel_errors = "หมายเลขโทรศัพท์ไม่ถูกต้อง"
        return;
      }

      try {
        let res = await REQUEST_GET(`/api/users/customers/search-phone/${tel}`)
        if(res.status==200){
          this.$router.push({
            name: "CustomerProfile",
            params: { id: res.data?.id },
          });
        }else{
          this.$router.push({
            name: "CustomerRegister",
            params: { phone: tel },
          });
        }

      }catch(e){
        this.$router.push({
          name: "CustomerRegister",
          params: { phone: tel },
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.operation-container {
  padding: 40px 65px;
  .action-button {
    min-width: 28px;
    padding: 0;
  }
}
</style>