<template>
  <div class="editor-point-score-container">
    <h1 class="mb-4">แก้ไขคะแนนเริ่มต้น</h1>
    <v-form :disabled="isLoading" v-model="valid" @submit.prevent="submitForm">
      <v-row class="text-field">
        <v-col class="pa-0" cols="12" sm="3" md="3" lg="2">
          <label>จำนวนบาท</label>
        </v-col>
        <v-col class="pa-0" cols="12" sm="4">
          <v-text-field
            placeholder="จำนวนบาท"
            outlined
            dense
            color="black"
            background-color="white"
            @keypress="validateNumeric"
            v-model="formData.amountPerPoint"
            :rules="[rules.required, rules.min]"
          />
        </v-col>
      </v-row>
      <v-divider class="mt-2" />
      <v-row>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn
            class="mt-4"
            color="#f7941e"
            type="submit"
            block
            :disabled="isLoading || validate || !valid"
          >
            บันทึก
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="3" lg="2">
          <v-btn class="mt-4" outlined block @click="backShopScore">กลับ</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from "lodash";

import Utils from "@/mixin";

export default {
  mixins: [Utils],
  computed: {
    validate() {
      const { amountPerPoint } = this.formData;
      if (!amountPerPoint) return true;
      return false;
    },
  },
  mounted() {
    const { point } = this;
    if (isEmpty(point)) this.backShopScore();
    const clonePoint = cloneDeep(point);
    this.formData = {
      amountPerPoint: clonePoint?.amountPerPoint?.toString() || "",
    };
  },
  data: () => ({
    valid: false,
    formData: {
      amountPerPoint: "",
    },
    rules: {
      required: (value) => !!value || "Require field.",
      min: (value) => value > 0 || "Value must be more than 0.",
    },
  }),
  props: {
    point: {
      type: Object,
    },
  },
  methods: {
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    backShopScore() {
      this.$router.push({
        name: "ShopScoreManagement",
      });
    },
    async submitForm() {
      const { formData } = this;
      const submitForm = {
        ...formData,
        amountPerPoint: parseInt(formData.amountPerPoint),
      };
      const res = await this.updateDefaultPoints(submitForm);
      const { status } = res;
      if (status === 200) {
        this.backShopScore();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-point-score-container {
  padding: 40px 65px;
}
</style>