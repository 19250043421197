import { REQUEST_GET, REQUEST_POST } from './API_Request'
import { errorMessage } from '@/plugins/common'

export const state = {
  users: {
    data: [],
    total: 0,
  },
}

export const mutations = {
  async GET_USERS(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_GET('/api/users', data.form)
      state.users.data = res?.data?.data || []
      state.users.total = res?.data?.total || 0
      data.toggleLoading()
      return state
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EXPORT_USER_POINT_RECEIVE(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/users/${data?.form?.userId}/points/receive-xlsx`,
        {},
        { responseType: 'blob' }
      )
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EXPORT_USER_POINT_DEDUCT(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/users/${data?.form?.userId}/points/deduct-xlsx`,
        {},
        { responseType: 'blob' }
      )
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
  async EXPORT_CUSTOMER(state, data) {
    data.toggleLoading()
    try {
      const res = await REQUEST_POST(
        `/api/users/customers/xlsx`,
        {},
        { responseType: 'blob' }
      )
      data.toggleLoading()
      return res?.data
    } catch (e) {
      data.toggleLoading()
      const error = errorMessage(e)
      return error
    }
  },
}

export const actions = {
  async getUsers(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.GET_USERS(state, { toggleLoading, form, toggleAlert })
  },
  async exportReceive(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EXPORT_USER_POINT_RECEIVE(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async exportDeduct(context, form) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EXPORT_USER_POINT_DEDUCT(state, {
      toggleLoading,
      form,
      toggleAlert,
    })
  },
  async exportCustomer(context) {
    const toggleLoading = () => context.dispatch('toggleLoading')
    const toggleAlert = (alertForm) =>
      context.dispatch('toggleAlertMessage', alertForm)
    return mutations.EXPORT_CUSTOMER(state, {
      toggleLoading,
      toggleAlert,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  users(state) {
    // eslint-disable-line
    return state.users
  },
}

export const users = {
  state,
  mutations,
  actions,
  getters,
}
